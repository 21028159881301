import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
  Collapse,
  Tooltip,
} from "@mui/material";
import { ChevronRight as ChevronRightIcon, InfoOutlined as InfoIcon } from "@mui/icons-material";
import { StyledTableContainerForExploreTables } from "../Dashboard/TablesCommon";
import styles from "./CategoryDashboard.module.css";
import { toast } from "react-toastify";

const ParasiteTable = ({ parasites, type, expandedRows, handleExpandClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getCategoryColor = (score) => {
    if (score >= 50) return '#4ECB71';
    if (score >= 20) return '#F8D66D';
    if (score >= 10) return '#FFB54C';
    return '#FF6961';
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: "#fff", py: 2, pt: 0 }}>
        {type} Parasites Overview
      </Typography>
      <StyledTableContainerForExploreTables className={styles.tableContainer}>
        <Table stickyHeader size={isMobile ? "small" : "medium"}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: '#2C2C2C', color: '#fff', width: '50%', paddingLeft: '30px' }}>Domain</TableCell>
              <TableCell sx={{ bgcolor: '#2C2C2C', color: '#fff', width: '50%' }}>Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={styles.tableBody}>
            {parasites.map((parasite, index) => (
              <React.Fragment key={parasite.parasite}>
                <TableRow sx={{ '&:nth-of-type(odd)': { bgcolor: '#252525' } }}>
                  <TableCell sx={{ color: '#fff', position: 'relative', paddingLeft: type === "Paid" ? '40px' : '16px' }}>
                    {type === "Paid" && (
                      <IconButton
                        onClick={() => handleExpandClick(parasite.parasite)}
                        sx={{
                          color: '#fff',
                          padding: '4px',
                          position: 'absolute',
                          left: '8px',
                          top: '50%',
                          transform: 'translateY(-50%)'
                        }}
                      >
                        <ChevronRightIcon sx={{
                          transform: expandedRows[parasite.parasite] ? 'rotate(90deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s'
                        }} />
                      </IconButton>
                    )}
                    <Box sx={{ paddingLeft: type === "Paid" ? '10px' : '0' }}>
                      <Typography
                        component="a"
                        href={`https://${parasite.parasite}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: '#fff',
                          textDecoration: 'none',
                          '&:hover': {
                            color: '#6DB9FF',
                            textDecoration: 'underline',
                          },
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {parasite.parasite}
                      </Typography>
                      {parasite.info && (
                        <Tooltip title={parasite.info}>
                          <InfoIcon
                            fontSize="small"
                            sx={{
                              marginLeft: 1,
                              verticalAlign: 'middle',
                              color: '#666',
                              cursor: 'pointer',
                              '&:hover': { color: '#fff' }
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <Box sx={{ width: '100%', bgcolor: '#333', borderRadius: 1, height: 10, overflow: 'hidden' }}>
                          <Box
                            sx={{
                              width: `${parasite.score}%`,
                              bgcolor: getCategoryColor(parasite.score),
                              height: '100%',
                            }}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ minWidth: 35, color: '#fff' }}>
                        {parasite.score}
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                {type === "Paid" && (
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                      <Collapse in={expandedRows[parasite.parasite]} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: '20px', display: 'flex', flexWrap: 'wrap', maxWidth: '100%', overflowX: 'hidden' }}>
                          <Box sx={{ width: '50%', pr: 2 }}>
                            <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
                              Parasite's Top Categories
                              <Tooltip title="These are the top categories for this parasite domain, NOT relative to other parasite domains.">
                                <InfoIcon
                                  fontSize="small"
                                  sx={{
                                    marginLeft: 1,
                                    verticalAlign: 'middle',
                                    color: '#666',
                                    cursor: 'pointer',
                                    '&:hover': { color: '#fff' }
                                  }}
                                />
                              </Tooltip>
                            </Typography>
                            {parasite.topCategories && parasite.topCategories.map((category, catIndex) => (
                              <Box key={catIndex} sx={{ mb: 1 }}>
                                <Typography variant="caption" sx={{ color: '#fff', display: 'block' }}>
                                  {category.category}
                                </Typography>
                                <Box sx={{ width: '100%', bgcolor: '#333', borderRadius: 1, height: 10, overflow: 'hidden' }}>
                                  <Box
                                    sx={{
                                      width: `${category.score}%`,
                                      bgcolor: getCategoryColor(category.score),
                                      height: '100%',
                                    }}
                                  />
                                </Box>
                              </Box>
                            ))}
                          </Box>
                          <Box sx={{ width: '50%', pl: 2 }}>
                            <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
                              Contact
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: parasite.contact ? '#6DB9FF' : '#fff',
                                cursor: parasite.contact && (parasite.contact.includes('@') || parasite.contact.includes('http')) ? 'pointer' : 'default',
                                '&:hover': {
                                  textDecoration: parasite.contact && (parasite.contact.includes('@') || parasite.contact.includes('http')) ? 'underline' : 'none',
                                },
                                wordBreak: 'break-word'
                              }}
                              onClick={() => {
                                if (parasite.contact) {
                                  if (parasite.contact.includes('@')) {
                                    navigator.clipboard.writeText(parasite.contact);
                                    toast.success('Email copied to clipboard');
                                  } else if (parasite.contact.includes('http')) {
                                    window.open(parasite.contact, '_blank');
                                  }
                                }
                              }}
                            >
                              {parasite.contact || "No contact info to show"}
                            </Typography>
                          </Box>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainerForExploreTables>
    </>
  );
};

export default ParasiteTable;