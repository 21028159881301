// src/theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#9333EA',
        },
        secondary: {
            main: '#65A30D',
        },
        background: {
            default: '#121212',
            paper: '#121212',
        },
    },
    typography: {
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                // @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
                
                body {
                    backgroundColor: '#121212';
                }

                 /* Custom scrollbar styling */
                ::-webkit-scrollbar {
                    width: 14px !important;
                    height: 14px !important;
                }

                ::-webkit-scrollbar-track {
                    background: #2c2c2c;
                }

                ::-webkit-scrollbar-thumb {
                    background-color: #9333EA;
                    border-radius: 10px;
                    border: 2px solid #2c2c2c;
                }

                ::-webkit-scrollbar-thumb:hover {
                    background-color: #7209b7;
                }

                /* Firefox scrollbar styling */
                body {
                    // scrollbar-color: #9333EA #2c2c2c;
                }
            `,
        },
    },
});

export default theme;