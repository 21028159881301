import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const searchEngines = [
  { name: "Google", value: "google" },
  { name: "Bing", value: "bing", comingSoon: true },
];

function SearchEngineSelector({ labelId, id, value, onChange, required }) {
  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue !== "bing") {
      onChange(newValue);
    }
  };

  return (
    <FormControl fullWidth required={required}>
      <InputLabel id={labelId}>Search Engine</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        value={value.toLowerCase()}
        onChange={handleChange}
        label="Search Engine"
        sx={{
          backgroundColor: '#2C2C2C',
          color: '#fff',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#333',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#444',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#666',
          },
          '& .MuiSvgIcon-root': {
            color: '#fff',
          },
          '& .MuiSelect-select': {
            '&:focus': {
              backgroundColor: 'transparent',
            },
          },
        }}
      >
        {searchEngines.map((engine) => (
          <MenuItem
            key={engine.value}
            value={engine.value}
            disabled={engine.comingSoon}
            sx={{
              opacity: engine.comingSoon ? 0.5 : 1,
              '&.Mui-disabled': {
                color: 'grey',
              },
            }}
          >
            {engine.name}
            {engine.comingSoon && " (Coming Soon)"}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SearchEngineSelector;