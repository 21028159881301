import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import { toast } from "react-toastify";

function ShareModal({ open, onClose, shareUrl }) {
  const [isCopied, setIsCopied] = useState(false);
  const textFieldRef = useRef(null);

  const copyToClipboard = async (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(text);
        return true;
      } catch (err) {
        console.error("Failed to copy text: ", err);
        return false;
      }
    }
    return false;
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      return document.execCommand("copy");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
      return false;
    } finally {
      document.body.removeChild(textArea);
    }
  };

  const handleCopy = async () => {
    let success = await copyToClipboard(shareUrl);

    if (!success) {
      success = fallbackCopyTextToClipboard(shareUrl);
    }

    if (success) {
      setIsCopied(true);
      toast.success("URL copied to clipboard");
      setTimeout(() => setIsCopied(false), 2000);
    } else {
      toast.error("Failed to copy URL");
    }
  };

  const handleSelectText = () => {
    if (textFieldRef.current) {
      textFieldRef.current.select();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          background: "#292929",
          color: "#fff",
        },
      }}
    >
      <DialogTitle>Share Keyword Analysis</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={shareUrl}
          inputRef={textFieldRef}
          onClick={handleSelectText}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="copy to clipboard"
                  onClick={handleCopy}
                  edge="end"
                  sx={{ color: isCopied ? "#4CAF50" : "inherit" }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#1E1E1E",
              "& fieldset": { borderColor: "#444" },
              "&:hover fieldset": { borderColor: "#666" },
              "&.Mui-focused fieldset": { borderColor: "#fff" },
            },
            "& .MuiInputLabel-root": { color: "#999" },
            "& input": { color: "#fff" },
          }}
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          sx={{ color: "#fff", mr: 1, bgcolor: "#595959" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShareModal;
