// BulkChecks.js
import React, { useState, useCallback, useRef, useEffect } from "react";
import { toast } from 'react-toastify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Chip,
  Typography,
  Card,
  CardContent,
  InputAdornment,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import CountrySelector from "../Dashboard/CountrySelector";
import CitySelector from "../Dashboard/CitySelector";
import SearchEngineSelector from "../Dashboard/SearchEngineSelector";
import ProjectSelector from "../ProjectSelector.js";
import AddProjectModal from "../Project/AddProjectModal";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useLimits } from "../../context/LimitsContext";
import styles from "./BulkChecks.module.css";
import generalApi from '../../api/generalApi';

const BulkChecks = () => {
  const [keywords, setKeywords] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [country, setCountry] = useState(
    () => localStorage.getItem("lastCountry") || "us"
  );
  const [city, setCity] = useState(() => {
    const savedCity = localStorage.getItem(`lastCity_${country}`);
    return savedCity
      ? JSON.parse(savedCity)
      : { canonicalName: "Any City", id: "any" };
  });
  const [searchEngine, setSearchEngine] = useState(
    () => localStorage.getItem("lastSearchEngine") || "Google"
  );
  const [selectedProject, setSelectedProject] = useState(null);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const inputRef = useRef(null);
  const { limits } = useLimits();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  const maxKeywords = limits?.planFeatures?.bulkKeywordLookupsAtATime || 50;
  const isBulkLookupAllowed = limits?.planFeatures?.bulkLookups || false;

  const fetchProjects = useCallback(async () => {
    try {
      const response = await generalApi.project.getProjectsLists();
      setProjects(response.results);
      const lastSelectedProjectId = localStorage.getItem("lastSelectedProject");
      if (lastSelectedProjectId) {
        const lastProject = response.results.find(p => p._id === lastSelectedProjectId);
        if (lastProject) {
          setSelectedProject(lastProject);
          setCountry(lastProject.country);
        }
      }
    } catch (error) {
      handleError(error);
    }
  }, [handleError]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      addKeyword(inputValue);
    }
  };

  const addKeyword = (value) => {
    const newKeywords = value
      .split(/[\n,]+/)
      .map((kw) => kw.trim())
      .filter((kw) => kw && !keywords.includes(kw));

    if (newKeywords.length > 0 && keywords.length < maxKeywords) {
      setKeywords([...keywords, ...newKeywords].slice(0, maxKeywords));
      setInputValue("");
      if (inputRef.current) {
        inputRef.current.scrollTop = inputRef.current.scrollHeight;
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text");
    addKeyword(pastedText);
  };

  const handleDeleteKeyword = (keywordToDelete) => {
    setKeywords(keywords.filter((keyword) => keyword !== keywordToDelete));
  };

  const handleCountryChange = (newCountry) => {
    setCountry(newCountry);
    localStorage.setItem("lastCountry", newCountry);
    setCity({ canonicalName: "Any City", id: "any" });
    setSelectedProject(null);
    localStorage.removeItem("lastSelectedProject");
  };

  const handleCityChange = useCallback(
    (newCity) => {
      setCity(newCity);
      if (newCity) {
        localStorage.setItem(`lastCity_${country}`, JSON.stringify(newCity));
      } else {
        localStorage.removeItem(`lastCity_${country}`);
      }
    },
    [country]
  );

  const handleSearchEngineChange = (newSearchEngine) => {
    setSearchEngine(newSearchEngine);
    localStorage.setItem("lastSearchEngine", newSearchEngine);
  };

  const handleProjectChange = (project) => {
    setSelectedProject(project);
    if (project) {
      setCountry(project.country);
      localStorage.setItem("lastSelectedProject", project._id);
    } else {
      localStorage.removeItem("lastSelectedProject");
    }
  };

  const handleBulkAnalysis = async () => {
    if (keywords.length === 0) {
      toast.error("Please enter at least one keyword.");
      return;
    }
    if (!selectedProject) {
      toast.error("Please select a project.");
      return;
    }
    try {
      setIsLoading(true);
      const response = await generalApi.bulkKeywordAnalysis(
        keywords,
        country,
        city?.canonicalName || "Any City",
        "en",
        searchEngine,
        selectedProject._id
      );
      
      const results = response.results;
      const totalKeywords = results.successfulAnalyses + results.failedAnalyses;
      
      if (results.successfulAnalyses === 0) {
        toast.error(`Bulk analysis failed. None of the ${totalKeywords} keywords were processed successfully.`);
      } else if (results.failedAnalyses === 0) {
        toast.success(`Bulk analysis completed successfully. All ${totalKeywords} keywords were processed.`);
      } else {
        toast.warn(
          `Bulk analysis completed with partial success. ${results.successfulAnalyses} out of ${totalKeywords} keywords were processed successfully.`
        );
      }
      
      // if (results.failedAnalyses > 0) {
      //   console.error("Failed keywords:", results.errors);
      //   toast.warn(`${results.failedAnalyses} keywords failed to process. Check console for details.`);
      // }
      
      if (results.successfulAnalyses > 0) {
        setTimeout(() => {
          navigate(results.url);
        }, 3000);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddProject = async (projectData) => {
    try {
      const response = await generalApi.project.createProject(projectData);
      const newProject = response.results;
      setProjects(prevProjects => [...prevProjects, newProject]);
      setSelectedProject(newProject);
      setCountry(newProject.country);
      localStorage.setItem("lastSelectedProject", newProject._id);
      setIsAddProjectModalOpen(false);
    } catch (error) {
      handleError(error);
    }
  };

  const isAnalysisDisabled =
    keywords.length === 0 || !selectedProject || !isBulkLookupAllowed;

  const LoadingOverlay = () => (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <CircularProgress />
    </Box>
  );

  return (
    <Box className={styles.bulkChecksContainer}>
      <Typography variant="h3" className={styles.title}>
        Bulk Parasite Analysis
      </Typography>
      <Typography variant="subtitle1" className={styles.subtitle}>
        Find the best parasites for multiple keywords at once
      </Typography>
      <Card className={styles.bulkChecksCard}>
        <CardContent>
          <Box className={styles.keywordsInputContainer}>
            <TextField
              inputRef={inputRef}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              onPaste={handlePaste}
              placeholder={`Enter keywords (press Enter or comma to add, max ${maxKeywords})`}
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={styles.searchIconWrapper}>
                    <SearchIcon className={styles.searchIcon} />
                  </InputAdornment>
                ),
                className: styles.keywordsInputField,
              }}
              className={styles.keywordsInput}
            />
            <Box className={styles.keywordChips}>
              {keywords.map((keyword, index) => (
                <Tooltip title={keyword.length > 30 ? keyword : ""} key={index}>
                  <Chip
                    label={keyword}
                    onDelete={() => handleDeleteKeyword(keyword)}
                    className={styles.keywordChip}
                  />
                </Tooltip>
              ))}
            </Box>
          </Box>
          <Typography variant="caption" className={styles.keywordCount}>
            {keywords.length} / {maxKeywords} keywords
            {maxKeywords < 500 && " (limited by plan)"}
          </Typography>
          <Box className={styles.selectors}>
            <ProjectSelector
              value={selectedProject}
              onChange={handleProjectChange}
              onAddProject={() => setIsAddProjectModalOpen(true)}
              className={`${styles.selector} ${styles.projectSelector}`}
              projects={projects}
            />
            <SearchEngineSelector
              value={searchEngine}
              onChange={handleSearchEngineChange}
              className={styles.selector}
            />
            <CountrySelector
              value={country}
              onChange={handleCountryChange}
              className={styles.selector}
              disabled={!!selectedProject}
            />
            <CitySelector
              country={country}
              value={city}
              onChange={handleCityChange}
              disabled={!country}
              searchEngine={searchEngine}
              className={styles.selector}
            />
          </Box>
          <Button
            variant="contained"
            onClick={handleBulkAnalysis}
            disabled={isAnalysisDisabled || isLoading}
            className={styles.analyzeButton}
          >
            {isLoading ? <CircularProgress size={24} /> : "Run Bulk Analysis"}
          </Button>
          {!isBulkLookupAllowed && (
            <Typography variant="caption" className={styles.errorMessage} component={RouterLink} to="/subscription">
              Bulk analysis is not available on your current plan.
              <Button
                variant="contained"
                className={styles.analyzeButton}
              >
                Upgrade
              </Button>
            </Typography>
          )}
        </CardContent>
      </Card>
      {isLoading && <LoadingOverlay />}
      <AddProjectModal
        open={isAddProjectModalOpen}
        onClose={() => setIsAddProjectModalOpen(false)}
        onSubmit={handleAddProject}
      />
    </Box>
  );
};

export default BulkChecks;