import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Box,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  TrendingUp as TrendsIcon,
  Folder as FolderIcon,
  Assessment as AssessmentIcon,
  School as SchoolIcon,
  Leaderboard as RankTrackerIcon,
  Lightbulb as WhatToPromoteIcon,
} from "@mui/icons-material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import SidenavCredits from "./SidenavCredits";
import SubscriptionLimitModal from "./SubscriptionLimitModal";
import { useLimits } from "../context/LimitsContext";

const drawerWidth = 240;
const collapsedDrawerWidth = 64;

const CustomTooltip = ({ children, title, disabled }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const ref = useRef(null);

  useEffect(() => {
    if (isHovered && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setPosition({
        top: rect.top + window.scrollY + rect.height / 2,
        left: rect.right + window.scrollX + 10,
      });
    }
  }, [isHovered]);

  if (!disabled) {
    return children;
  }

  return (
    <div
      ref={ref}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      {isHovered &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "absolute",
              top: `${position.top}px`,
              left: `${position.left}px`,
              transform: "translateY(-50%)",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              color: "white",
              padding: "5px 10px",
              borderRadius: "4px",
              fontSize: "14px",
              whiteSpace: "nowrap",
              zIndex: 9999,
            }}
          >
            {title}
          </div>,
          document.body
        )}
    </div>
  );
};

function Sidenav({ open, onClose, isMobile }) {
  const theme = useTheme();
  const location = useLocation();
  const sidenavRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const { limits } = useLimits();

  const menuItems = [
    { icon: <DashboardIcon />, text: "Dashboard", path: "/dashboard" },
    { icon: <TrendsIcon />, text: "Trending", path: "/trending" },
    { icon: <FolderIcon />, text: "Projects", path: "/projects" },
    {
      icon: <AssessmentIcon />,
      text: "Bulk Analysis",
      path: "/bulk-checks",
    },
    { icon: <SchoolIcon />, text: "Training", path: "/training" },
    { 
      icon: <RankTrackerIcon />, 
      text: "Rank Tracker", 
      disabled: true, 
      tooltip: "Coming in October" 
    },
    { 
      icon: <WhatToPromoteIcon />, 
      text: "What to Promote", 
      disabled: true, 
      tooltip: "Coming in October" 
    },
  ];

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidenavRef.current && !sidenavRef.current.contains(event.target)) {
        if (isMobile) {
          onClose();
        } else {
          setIsExpanded(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, onClose]);

  return (
    <>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? open : true}
        onClose={onClose}
        ref={sidenavRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          width: isMobile
            ? drawerWidth
            : isExpanded
            ? drawerWidth
            : collapsedDrawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: isMobile
              ? drawerWidth
              : isExpanded
              ? drawerWidth
              : collapsedDrawerWidth,
            boxSizing: "border-box",
            backgroundColor: "rgba(28, 28, 28, 0.8)",
            backdropFilter: "blur(4px)",
            borderRight: "none",
            position: "fixed",
            top: 0,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >
        <Box sx={{ height: 64, flexShrink: 0 }} />
        <Box
          sx={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden", mb: 2 }}
        >
          <List>
            {menuItems.map((item, index) => (
              <CustomTooltip
                key={index}
                title={item.tooltip}
                disabled={item.disabled}
              >
                <ListItem
                  button
                  component={item.onClick || item.disabled ? "div" : RouterLink}
                  to={item.onClick || item.disabled ? undefined : item.path}
                  onClick={item.onClick}
                  selected={location.pathname === item.path}
                  disabled={item.disabled}
                  sx={{
                    justifyContent:
                      isExpanded || isMobile ? "flex-start" : "center",
                    px: isExpanded || isMobile ? 2.5 : 1,
                    opacity: item.disabled ? 0.5 : 1,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isExpanded || isMobile ? 3 : 0,
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {(isExpanded || isMobile) && (
                    <ListItemText
                      primary={item.text}
                      sx={{
                        color: "white",
                        opacity: isExpanded || isMobile ? 1 : 0,
                        transition: theme.transitions.create("opacity", {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.enteringScreen,
                        }),
                      }}
                    />
                  )}
                </ListItem>
              </CustomTooltip>
            ))}
          </List>
        </Box>
        <SidenavCredits isExpanded={isExpanded || isMobile} />
      </Drawer>
      <SubscriptionLimitModal
        open={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
      />
    </>
  );
}

export default Sidenav;