import React, { useState, useRef, useEffect } from 'react';
import { Card, Typography, Box, IconButton, Tooltip } from '@mui/material';
import { Info as InfoIcon, Edit as EditIcon, Lock as LockIcon } from '@mui/icons-material';
import ReactDOM from 'react-dom';
import styles from './ScoreCard.module.css';
import { useLimits } from "../context/LimitsContext";

const ScoreCircle = ({ score, color, categories = null, primaryCategory, onHover }) => {
  const radius = 47.5;
  const strokeWidth = 14;
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = 2 * Math.PI * normalizedRadius;
  const strokeDashoffset = circumference - (score / 100) * circumference;
  const gapWidth = 5;

  if (categories) {
    const totalCategories = categories.length;
    let percentages;

    switch(totalCategories) {
      case 2:
        percentages = [66, 34];
        break;
      case 3:
        percentages = [50, 25, 25];
        break;
      case 4:
        percentages = [40, 20, 20, 20];
        break;
      case 5:
        percentages = [40, 15, 15, 15, 15];
        break;
      default:
        percentages = [100];
    }

    const orderedCategories = [
      categories.find(cat => cat.category === primaryCategory),
      ...categories.filter(cat => cat.category !== primaryCategory)
    ].filter(Boolean);

    let totalPercentage = 0;

    return (
      <svg width="100%" height="100%" viewBox="0 0 100 100" className={styles.scoreCircle}>
        <circle cx="50" cy="50" r={radius} fill="#121212" />
        {orderedCategories.slice(0, 5).map((category, index) => {
          const percentage = percentages[index] / 100;
          const arcLength = percentage * 360;
          const startAngle = totalPercentage * 360;
          totalPercentage += percentage;

          const startRadians = (startAngle - 90) * Math.PI / 180;
          const endRadians = (startAngle + arcLength - 90) * Math.PI / 180;

          const innerRadius = radius - strokeWidth;
          const outerRadius = radius;

          const x1 = 50 + innerRadius * Math.cos(startRadians);
          const y1 = 50 + innerRadius * Math.sin(startRadians);
          const x2 = 50 + outerRadius * Math.cos(startRadians);
          const y2 = 50 + outerRadius * Math.sin(startRadians);
          const x3 = 50 + outerRadius * Math.cos(endRadians);
          const y3 = 50 + outerRadius * Math.sin(endRadians);
          const x4 = 50 + innerRadius * Math.cos(endRadians);
          const y4 = 50 + innerRadius * Math.sin(endRadians);

          const largeArcFlag = arcLength > 180 ? 1 : 0;

          return (
            <g key={index}>
              <path
                d={`M ${x1} ${y1} L ${x2} ${y2} A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${x3} ${y3} L ${x4} ${y4} A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${x1} ${y1}`}
                fill={index === 0 ? "#4ECB71" : "#2C2C2C"}
                className={styles.categorySlice}
                onMouseEnter={() => onHover(orderedCategories[index])}
                onMouseLeave={() => onHover(null)}
              />
              <path
                d={`M 50 50 L ${x2} ${y2}`}
                stroke="#121212"
                strokeWidth={gapWidth}
                fill="none"
                className={styles.categoryGap}
              />
            </g>
          );
        })}
      </svg>
    );
  }

  return (
    <svg width="100%" height="100%" viewBox="0 0 100 100" className={styles.scoreCircle}>
      <circle
        stroke="#121212"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx="50"
        cy="50"
      />
      <circle
        stroke={color}
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx="50"
        cy="50"
      />
      <text x="50" y="45" textAnchor="middle" dy=".3em" className={styles.scoreValue}>
        {score}
      </text>
      <text x="50" y="70" textAnchor="middle" className={styles.scoreMaxValue}>
        /100
      </text>
    </svg>
  );
};

const SubheadingWithHighlight = ({ free, paid, freeTooltip, paidTooltip, isPaidLimited, paidLimitTooltip }) => {
  const [higher, lower] = free > paid 
    ? [{ type: 'Free', value: free, tooltip: freeTooltip }, { type: 'Paid', value: paid, tooltip: paidTooltip }]
    : [{ type: 'Paid', value: paid, tooltip: paidTooltip }, { type: 'Free', value: free, tooltip: freeTooltip }];

  return (
    <div className={styles.subheadingContainer}>
      <Tooltip title={higher.tooltip} placement="top">
        <div className={`${styles.subheading} ${styles[higher.type.toLowerCase()]}`}>
          {`${higher.type}: ${higher.value}`}
          {higher.type === 'Paid' && isPaidLimited && (
            <LockIcon fontSize="small" sx={{ marginLeft: '4px', verticalAlign: 'middle' }} />
          )}
        </div>
      </Tooltip>
      <Tooltip title={lower.type === 'Paid' && isPaidLimited ? paidLimitTooltip : lower.tooltip} placement="top">
        <div className={styles.lowerValue}>
          {`${lower.type}: ${lower.value}`}
          {lower.type === 'Paid' && isPaidLimited && (
            <LockIcon fontSize="small" sx={{ marginLeft: '4px', verticalAlign: 'middle' }} />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

const CategoryTooltip = ({ category, position, categories, primaryCategory }) => {
  const [tooltipStyle, setTooltipStyle] = useState({});

  useEffect(() => {
    const tooltipWidth = 200;
    const screenWidth = window.innerWidth;
    const rightSpace = screenWidth - position.x;
    
    let left = position.x;
    if (rightSpace < tooltipWidth) {
      left = Math.max(0, position.x - tooltipWidth);
    }

    setTooltipStyle({
      left: `${left}px`,
      top: `${position.y}px`,
      maxWidth: `${Math.min(tooltipWidth, screenWidth)}px`
    });
  }, [position]);

  const totalCategories = categories.length;
  let percentages;

  switch(totalCategories) {
    case 2:
      percentages = [66, 34];
      break;
    case 3:
      percentages = [50, 25, 25];
      break;
    case 4:
      percentages = [40, 20, 20, 20];
      break;
    case 5:
      percentages = [40, 15, 15, 15, 15];
      break;
    default:
      percentages = [100];
  }

  const orderedCategories = [
    categories.find(cat => cat.category === primaryCategory),
    ...categories.filter(cat => cat.category !== primaryCategory)
  ].filter(Boolean);

  const categoryIndex = orderedCategories.findIndex(cat => cat.category === category.category);
  const categoryPercentage = percentages[categoryIndex];

  return ReactDOM.createPortal(
    <div className={styles.categoryTooltip} style={tooltipStyle}>
      <Typography variant="subtitle2">
        {category.category} {category.category === primaryCategory ? '(Primary)' : ''} - {categoryPercentage}%
      </Typography>
      <Typography variant="caption">
        {category.subcategories.join(', ')}
      </Typography>
    </div>,
    document.body
  );
};

const ScoreCard = ({ title, value, score, color, infoTooltip, onEdit, subheadings, categorization, primaryCategoryHoverContent }) => {
  const isPrimaryCategory = !!categorization;
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const cardRef = useRef(null);
  const { limits } = useLimits();

  const handleMouseMove = (event) => {
    if (cardRef.current) {
      setTooltipPosition({
        x: event.clientX,
        y: event.clientY
      });
    }
  };

  useEffect(() => {
    if (isPrimaryCategory && cardRef.current) {
      cardRef.current.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      if (isPrimaryCategory && cardRef.current) {
        cardRef.current.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [isPrimaryCategory]);

  const isPaidLimited = () => {
    if (!limits || !subheadings) return false;
    const planId = limits.subscriptionPlanTypeId;
    if (title === "Parasite Opportunity Score") {
      return (
        (planId === 0 || planId === 1 || planId === 11) ||
        (planId === 2 || planId === 12)
      );
    } else if (title === "SERP Parasite Score") {
      return (planId === 0 || planId === 1 || planId === 11);
    }
    return false;
  };

  const getPaidLimitTooltip = () => {
    if (title === "Parasite Opportunity Score") {
      if (limits.subscriptionPlanTypeId === 2 || limits.subscriptionPlanTypeId === 12) {
        return "Preview limited to 10 paid opportunities by plan";
      }
      return "Paid opportunities not available on your plan";
    } else if (title === "SERP Parasite Score") {
      return "Paid parasite data not available on your plan";
    }
    return "";
  };

  return (
    <Card className={styles.scoreCard} ref={cardRef}>
      <div className={styles.cardContent}>
        <div className={styles.header}>
          <Typography variant="body2" className={styles.title}>
            {title}
            <Tooltip title={infoTooltip} placement="top">
              <IconButton size="small" className={styles.infoButton}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
        </div>
        <div className={styles.body}>
          <div className={styles.leftContent}>
            <Typography variant="h4" className={`${styles.value} ${isPrimaryCategory ? styles.primaryCategoryValue : ''}`}>
              {value}
            </Typography>
            {!isPrimaryCategory && subheadings && (
        <SubheadingWithHighlight 
          free={subheadings.free} 
          paid={subheadings.paid} 
          freeTooltip={title === "Parasite Opportunity Score" 
            ? "The number of free parasite opportunities we found. May be limited by plan."
            : "Number of free parasite domains found in top 100 results"}
          paidTooltip={title === "Parasite Opportunity Score"
            ? "The number of paid parasite opportunities we found. May be limited by plan."
            : "Number of paid parasite domains found in top 100 results"}
          isPaidLimited={isPaidLimited()}
          paidLimitTooltip={getPaidLimitTooltip()}
        />
      )}
            {isPrimaryCategory && categorization.categories.find(cat => cat.category === categorization.primaryCategory).subcategories.length > 0 && (
              <Typography variant="body2" className={styles.subcategories}>
                {categorization.categories.find(cat => cat.category === categorization.primaryCategory).subcategories.join(', ')}
              </Typography>
            )}
          </div>
          <div className={styles.rightContent}>
            <ScoreCircle 
              score={score} 
              color={color}
              categories={isPrimaryCategory ? categorization.categories : null}
              primaryCategory={categorization?.primaryCategory}
              onHover={setHoveredCategory}
            />
          </div>
        </div>
      </div>
      {isPrimaryCategory && hoveredCategory !== null && (
        <CategoryTooltip 
          category={hoveredCategory}
          position={tooltipPosition}
          categories={categorization.categories}
          primaryCategory={categorization.primaryCategory}
        />
      )}
      {isPrimaryCategory && primaryCategoryHoverContent && (
        <Tooltip title={primaryCategoryHoverContent} placement="top">
          <div className={styles.primaryCategoryHoverArea}></div>
        </Tooltip>
      )}
    </Card>
  );
};

export default ScoreCard;