// src/context/AppContext.js
import React, { createContext, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../utils/translations';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    const showErrorToast = useCallback((errorCode) => {
        const message = getErrorMessage(errorCode);
        toast.error(message);
    }, []);

    return (
        <AppContext.Provider value={{
            showErrorToast, showSubscriptionModal,
            setShowSubscriptionModal }}>
            {children}
        </AppContext.Provider>
    );
};