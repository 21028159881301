import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, Link, Tooltip, Button, Collapse, IconButton } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import { ParasiteTypeIndicator, StyledTableContainer } from './TablesCommon';
import { toast } from 'react-toastify';
import { useLimits } from "../../context/LimitsContext";

function SerpOverviewTable({ serps }) {
  const [parasiteFilter, setParasiteFilter] = useState('all');
  const [expandedRows, setExpandedRows] = useState({});
  const { limits } = useLimits();

  const filteredSerps = serps.filter(row => {
    if (parasiteFilter === 'all') return true;
    if (parasiteFilter === 'free') return row.isParasite && row.parasiteType === 'free';
    if (parasiteFilter === 'paid') return row.isParasite && row.parasiteType === 'paid';
    return true;
  });

  const formatUrl = (url, domain) => {
    const urlWithoutProtocol = url.replace(/^(https?:\/\/)?(www\.)?/, '');
    const urlAfterDomain = urlWithoutProtocol.replace(new RegExp(`^${domain.replace(/\./g, '\\.')}/?`), '');
    return urlAfterDomain.length > 20 ? urlAfterDomain.substring(0, 20) + '...' : urlAfterDomain;
  };

  const handleExpandClick = (position) => {
    setExpandedRows(prev => ({ ...prev, [position]: !prev[position] }));
  };

  const handleContactClick = (contact) => {
    if (contact) {
      if (contact.includes('@')) {
        navigator.clipboard.writeText(contact);
        toast.success('Email copied to clipboard');
      } else if (contact.includes('http')) {
        window.open(contact, '_blank');
      }
    }
  };

  const getCategoryColor = (score) => {
    if (score >= 50) return '#4ECB71';
    if (score >= 20) return '#F8D66D';
    if (score >= 10) return '#FFB54C';
    return '#FF6961';
  };

  const isPaidDisabled = limits && (limits.subscriptionPlanTypeId === 0 || limits.subscriptionPlanTypeId === 1 || limits.subscriptionPlanTypeId === 11);

  if (!serps || serps.length === 0) {
    return (
      <Typography sx={{ color: '#fff', textAlign: 'center', py: 2 }}>
        No SERP results could be found.
      </Typography>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, width: "100%" }}>
        <Typography variant="h6" sx={{ color: '#fff' }}>
          SERP Overview
        </Typography>
        <Box>
          <Button
            variant={parasiteFilter === 'all' ? 'outlined' : 'text'}
            color="primary"
            onClick={() => setParasiteFilter('all')}
            sx={{ 
              mr: 1, 
              borderColor: parasiteFilter === 'all' ? '#fff' : 'transparent', 
              color: '#fff',
              opacity: parasiteFilter === 'all' ? 1 : 0.75
            }}
          >
            All
          </Button>
          <Button
            variant={parasiteFilter === 'free' ? 'contained' : 'text'}
            color="primary"
            onClick={() => setParasiteFilter('free')}
            sx={{ 
              mr: 1, 
              bgcolor: parasiteFilter === 'free' ? '#4ECB71' : 'transparent',
              color: '#fff',
              opacity: parasiteFilter === 'free' ? 1 : 0.75
            }}
          >
            Free
          </Button>
          <Tooltip title={isPaidDisabled ? "Paid parasite opportunities are not available on your plan. Please upgrade to unlock them." : ""}>
            <span>
              <Button
                variant={parasiteFilter === 'paid' ? 'contained' : 'text'}
                color="primary"
                onClick={() => !isPaidDisabled && setParasiteFilter('paid')}
                disabled={isPaidDisabled}
                sx={{ 
                  bgcolor: parasiteFilter === 'paid' ? '#9333EA' : 'transparent',
                  color: '#fff',
                  opacity: parasiteFilter === 'paid' ? 1 : 0.75
                }}
              >
                Paid
                {isPaidDisabled && <LockIcon sx={{ ml: 1, fontSize: 16 }} />}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: '#2C2C2C', color: '#fff', width: '20px' }}>Position</TableCell>
              <TableCell sx={{ bgcolor: '#2C2C2C', color: '#fff' }}>Result</TableCell>
              <TableCell sx={{ bgcolor: '#2C2C2C', color: '#fff', width: '20px' }}>Parasite</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSerps.map((row) => (
              <React.Fragment key={row.position}>
                <TableRow sx={{ '&:nth-of-type(odd)': { bgcolor: '#252525' } }}>
                  <TableCell sx={{ color: '#fff', textAlign: 'center', position: 'relative', paddingLeft: '30px' }}>
                    {row.isParasite && row.parasiteType === 'paid' && !isPaidDisabled && (
                      <IconButton
                        onClick={() => handleExpandClick(row.position)}
                        sx={{ 
                          color: '#fff', 
                          padding: '4px', 
                          position: 'absolute', 
                          left: '10px', 
                          top: '50%', 
                          transform: 'translateY(-50%)'
                        }}
                      >
                        <ChevronRightIcon sx={{ 
                          transform: expandedRows[row.position] ? 'rotate(90deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s'
                        }} />
                      </IconButton>
                    )}
                    {row.position}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', maxWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <Tooltip title={row.title} placement="top">
                      <Typography noWrap>
                        {row.title}
                      </Typography>
                    </Tooltip>
                    <Link href={row.link} target="_blank" rel="noopener" sx={{ color: '#6DB9FF', display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Box component="span" sx={{ fontWeight: 'bold' }}>{row.domain}</Box>
                      <Box component="span" sx={{ fontSize: '0.8em', marginLeft: '2px' }}>
                        /{formatUrl(row.link, row.domain)}
                      </Box>
                    </Link>
                    {row.isParasite && row.sitelinks && row.sitelinks.length > 0 && (
                      <Box sx={{ paddingTop: '8px', paddingLeft: '6px' }}>
                        {row.sitelinks.filter(sitelink => sitelink.link.startsWith('http')).map((sitelink, index) => (
                          <Link
                            key={index}
                            href={sitelink.link}
                            target="_blank"
                            rel="noopener"
                            sx={{
                              color: '#fff',
                              display: 'block',
                              fontSize: '0.9em',
                              padding: '2px',
                              textDecoration: 'underline',
                              '&:hover': {
                                color: '#6DB9FF',
                                textDecoration: 'underline',
                              },
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {sitelink.title}
                          </Link>
                        ))}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell sx={{ color: '#fff', textAlign: 'center' }}>
                    {row.isParasite && (!isPaidDisabled || row.parasiteType === 'free') && (
                      <Tooltip title={`${row.parasiteType === 'free' ? 'Free' : 'Paid'} Parasite Domain${row.info ? `; ${row.info}` : ''}`}>
                        <span>
                          <ParasiteTypeIndicator type={row.parasiteType} />
                        </span>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
                {row.isParasite && row.parasiteType === 'paid' && !isPaidDisabled && (
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                      <Collapse in={expandedRows[row.position]} timeout="auto" unmountOnExit>
                        <Box sx={{ padding: '20px', display: 'flex', flexWrap: 'wrap' }}>
                          <Box sx={{ width: '50%', pr: 2 }}>
                            <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
                              Parasite's Top Categories
                            </Typography>
                            {row.categories && row.categories.map((category, catIndex) => (
                              <Box key={catIndex} sx={{ mb: 1 }}>
                                <Typography variant="caption" sx={{ color: '#fff', display: 'block' }}>
                                  {category.category}
                                </Typography>
                                <Box sx={{ width: '100%', bgcolor: '#333', borderRadius: 1, height: 10, overflow: 'hidden' }}>
                                  <Box
                                    sx={{
                                      width: `${category.score}%`,
                                      bgcolor: getCategoryColor(category.score),
                                      height: '100%',
                                    }}
                                  />
                                </Box>
                              </Box>
                            ))}
                          </Box>
                          <Box sx={{ width: '50%', pl: 2 }}>
                            <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
                              Contact
                            </Typography>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                color: row.contact ? '#6DB9FF' : '#fff', 
                                cursor: row.contact && (row.contact.includes('@') || row.contact.includes('http')) ? 'pointer' : 'default',
                                '&:hover': {
                                  textDecoration: row.contact && (row.contact.includes('@') || row.contact.includes('http')) ? 'underline' : 'none',
                                },
                                wordBreak: 'break-word'
                              }}
                              onClick={() => handleContactClick(row.contact)}
                            >
                              {row.contact || "No contact info to show"}
                            </Typography>
                          </Box>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
}

export default SerpOverviewTable;