import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { StyledTableContainer } from "./TablesCommon";
import { formatNumber } from "../../utils/numberFormat";


const DifficultyCircle = ({ kd }) => {
  let color;
  if (kd < 40) color = "#4ECB71";
  else if (kd < 50) color = "#F8D66D";
  else if (kd < 70) color = "#FFB54C";
  else color = "#FF6961";

  return (
    <Box
      sx={{
        width: 38,
        height: 38,
        borderRadius: "50%",
        border: `4px solid ${color}`,
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      {kd}
    </Box>
  );
};

function KeywordIdeasTable({ ideas, onKeywordClick }) {
  if (!ideas || ideas.length === 0) {
    return (
      <Typography sx={{ color: "#fff", textAlign: "center", py: 2 }}>
        No keyword ideas could be found.<br/><br/> Our keywords database is growing and will be updated when we get out of Beta (next month).
      </Typography>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography
          variant="h6"
          sx={{ color: "#fff", fontWeight: "normal", mr: 1 }}
        >
          Keyword Ideas
        </Typography>
        <Tooltip title="This is not a keyword tool (yet!), but our database is growing and we'll have updated keywords after Beta (Sept 25th)." arrow>
          <IconButton size="small">
            <InfoIcon fontSize="small" sx={{ color: '#fff' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", width: "36%" }}
              >
                Keyword
              </TableCell>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", width: "18%", textAlign: "center" }}
              >
                Volume
              </TableCell>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", width: "12%", textAlign: "center" }}
              >
                CPC
              </TableCell>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", width: "22%", textAlign: "center" }}
              >
                Trends
              </TableCell>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", width: "12%", textAlign: "center" }}
              >
                Difficulty
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ideas.map((idea, index) => (
              <TableRow
                key={index}
                sx={{ "&:nth-of-type(odd)": { bgcolor: "#252525" } }}
              >
                <TableCell
                  sx={{
                    color: "#fff",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                      color: "#6DB9FF",
                    },
                  }}
                  onClick={() => onKeywordClick(idea.keyword)}
                >
                  {idea.keyword}
                </TableCell>
                <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                  {formatNumber(idea.volume)}
                </TableCell>
                <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                  ${idea.cpc.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", height: 20, justifyContent: "center" }}
                  >
                    {idea.trends.map((trend, i) => (
                      <Box
                        key={i}
                        sx={{
                          width: 5,
                          height: `${trend}%`,
                          backgroundColor: "#9333EA",
                          mx: 0.5,
                        }}
                      />
                    ))}
                  </Box>
                </TableCell>
                <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <DifficultyCircle kd={idea.kd} />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
}

export default KeywordIdeasTable;