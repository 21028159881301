import React from 'react';
import { Typography, Box, Tooltip, Link, Divider } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const RedditOpportunityExpanded = ({ opportunity }) => {
  const getCategoryColor = (score) => {
    if (score >= 50) return '#4ECB71';
    if (score >= 20) return '#F8D66D';
    if (score >= 10) return '#FFB54C';
    return '#FF6961';
  };

  if (!opportunity.subreddits || opportunity.subreddits.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '20px' }}>
        <Typography variant="body1" sx={{ color: '#fff', textAlign: 'center' }}>
          No subreddits to show for this country/category. Coming very soon!
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxWidth: '100%', marginTop: '-21px' }}>
      <Box
        sx={{
          position: 'sticky',
          top: 'calc(1.2em + 40px)',
          minHeight: '60px',
          backgroundColor: '#1E1E1E',
          borderBottom: '1px solid #444',
          padding: '25px 15px 20px 15px',
        }}
      >
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box sx={{ width: '41%', pr: 2 }}>
            <Typography variant="subtitle2" sx={{ color: '#fff', display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
              Top Subreddits
              <Tooltip title="The best ranking subreddits for your parasite niche categories">
                <InfoIcon
                  fontSize="small"
                  sx={{
                    marginLeft: 1,
                    color: '#666',
                    cursor: 'pointer',
                    '&:hover': { color: '#fff' }
                  }}
                />
              </Tooltip>
            </Typography>
          </Box>
          <Box sx={{ width: '59%', pl: 2 }}>
            <Typography variant="subtitle2" sx={{ color: '#fff' }}>
              Subreddit Parasite Category Score
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: '10px 20px' }}>
        {opportunity.subreddits.map((subreddit, index) => (
          <React.Fragment key={index}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
              <Box sx={{ width: '41%', pr: 2, wordWrap: 'break-word' }}>
                <Link
                  href={`https://reddit.com${subreddit.subredditName}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: '#6DB9FF',
                    paddingLeft: '5px',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {subreddit.subredditName}
                </Link>
              </Box>
              <Box sx={{ width: '59%', pl: 2 }}>
                {subreddit.categoryScores.map((category, catIndex) => (
                  <Box key={catIndex} sx={{ mb: 1 }}>
                    <Typography variant="caption" sx={{ color: '#fff', display: 'block' }}>
                      {category.category}
                    </Typography>
                    <Box sx={{ width: '100%', bgcolor: '#333', borderRadius: 1, height: 10, overflow: 'hidden' }}>
                      <Box
                        sx={{
                          width: `${category.score}%`,
                          bgcolor: getCategoryColor(category.score),
                          height: '100%',
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            {index < opportunity.subreddits.length - 1 && (
              <Box sx={{ width: '100%', my: 2 }}>
                <Divider sx={{ bgcolor: '#444' }} />
              </Box>
            )}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default RedditOpportunityExpanded;