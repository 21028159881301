// src/components/ProjectScreen.js
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  TextField,
  InputAdornment,
  Menu,
  MenuItem,
  Tooltip,
  Paper,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Add as AddIcon,
  Folder as FolderIcon,
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  FileCopy as FileCopyIcon,
  GetApp as GetAppIcon,
} from "@mui/icons-material";
import AddProjectModal from "./AddProjectModal";
import generalApi from "../../api/generalApi";
import { toast } from "react-toastify";
import { StyledTableContainerProjectKeyword } from "../Dashboard/TablesCommon";
// eslint-disable-next-line no-unused-vars
import { formatNumber } from "../../utils/numberFormat";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import styles from "./TableOverrides.css";
import ReactCountryFlag from "react-country-flag";

const DifficultyCircle = ({ score, type }) => {
  let color;
  if (type === "opportunity") {
    if (score >= 70) color = "#4ECB71";
    else if (score >= 50) color = "#F8D66D";
    else if (score >= 20) color = "#FFB54C";
    else color = "#FF6961";
  } else if (type === "parasite") {
    if (score < 40) color = "#4ECB71";
    else if (score < 60) color = "#F8D66D";
    else if (score < 80) color = "#FFB54C";
    else color = "#FF6961";
  }

  return (
    <Box
      sx={{
        width: 38,
        height: 38,
        borderRadius: "50%",
        border: `4px solid ${color}`,
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        fontWeight: "bold",
        textAlign: "center",
        margin: "0 auto",
      }}
    >
      {score}
    </Box>
  );
};

function ProjectKeywordTable({
  keywords = [],
  onEditKeyword,
  onDeleteKeyword,
  selectedKeywords,
  onSelectKeyword,
  countryIsoCode,
}) {
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const handleKeywordClick = (event,keywordId) => {
    event.preventDefault(); // Prevent default link behavior

    if (event.button === 1) {
      // Middle mouse button click
      window.open(`/dashboard/${keywordId}`, "_blank");
    } else if (event.button === 0) {
      // Left mouse button click
      navigate(`/dashboard/${keywordId}`);
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const formatDateTime = (date) => {
    return new Date(date).toLocaleString();
  };

  if (!keywords || keywords.length === 0) {
    return (
      <Box sx={{ 
        height: { xs: 'auto', sm: 'calc(100vh - 300px)' },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Typography sx={{ color: "#fff", textAlign: "center", py: 2 }}>
          No keywords found for this project. Use the dashboard to add keywords to
          a project first.
        </Typography>
      </Box>
    );
  }

  return (
    <StyledTableContainerProjectKeyword className={styles.fixedTable}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ bgcolor: "#2C2C2C", color: "#fff" }}>
              <Checkbox
                checked={selectedKeywords.length === keywords.length}
                indeterminate={
                  selectedKeywords.length > 0 &&
                  selectedKeywords.length < keywords.length
                }
                onChange={(e) =>
                  onSelectKeyword(
                    e.target.checked ? keywords.map((k) => k._id) : []
                  )
                }
                sx={{ color: "#fff" }}
              />
            </TableCell>
            <TableCell sx={{ bgcolor: "#2C2C2C", color: "#fff" }}>
              Keyword
            </TableCell>
            <>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", textAlign: "center" }}
              >
                {(countryIsoCode || "US").toUpperCase()} Volume
              </TableCell>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", textAlign: "center" }}
              >
                Parasite Opportunity Score
              </TableCell>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", textAlign: "center" }}
              >
                SERP Parasite Score
              </TableCell>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", textAlign: "center" }}
              >
                Niche Category
              </TableCell>
              <TableCell
                sx={{ bgcolor: "#2C2C2C", color: "#fff", textAlign: "center" }}
              >
                Updated
              </TableCell>
            </>
          </TableRow>
        </TableHead>
        <TableBody>
          {keywords.map((keyword, index) => (
            <TableRow
              key={keyword._id || index}
              sx={{ "&:nth-of-type(odd)": { bgcolor: "#252525" } }}
            >
              <TableCell>
                <Checkbox
                  checked={selectedKeywords.includes(keyword._id)}
                  onChange={() => onSelectKeyword(keyword._id)}
                  sx={{ color: "#fff" }}
                />
              </TableCell>
              <TableCell>
                <Typography
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/dashboard/${keyword._id}`} // Add href for middle-click functionality
                  onClick={(event) => handleKeywordClick(event, keyword._id)}
                  onAuxClick={(event) => handleKeywordClick(event, keyword._id)} // Handle middle-click
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#6DB9FF",
                      textDecoration: "underline",
                    },
                  }}
                >
                  {keyword.kw}
                </Typography>
              </TableCell>
              <>
                <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                  {keyword.volume ? formatNumber(keyword.volume) : "N/A"}
                </TableCell>
                <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                  <DifficultyCircle
                    score={keyword.opportunityScore || 0}
                    type="opportunity"
                  />
                </TableCell>
                <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                  <DifficultyCircle
                    score={keyword.parasiteScore || 0}
                    type="parasite"
                  />
                </TableCell>
                <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                  {keyword.category || "N/A"}
                </TableCell>
                <TableCell sx={{ color: "#fff", textAlign: "center" }}>
                  <Tooltip title={formatDateTime(keyword.updatedAt)} arrow>
                    <span>{formatDate(keyword.updatedAt)}</span>
                  </Tooltip>
                </TableCell>
              </>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainerProjectKeyword>
  );
}

function ProjectScreen() {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectKeywords, setProjectKeywords] = useState([]);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [editingProject, setEditingProject] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isDeleteKeywordConfirmOpen, setIsDeleteKeywordConfirmOpen] =
    useState(false);
  const handleError = useErrorHandler();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [isKeywordsLoading, setIsKeywordsLoading] = useState(false);

  const fetchProjects = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await generalApi.project.getProjectsLists();
      setProjects(response.results);

      // Get the project ID from the URL query parameter
      const projectIdFromUrl = new URLSearchParams(location.search).get(
        "project"
      );

      if (response.results.length > 0) {
        const projectToSelect = projectIdFromUrl
          ? response.results.find((p) => p._id === projectIdFromUrl)
          : response.results[0];

        if (projectToSelect) {
          await handleSelectProject(projectToSelect);
        }
        setSelectedProject(projectToSelect);
        await fetchProjectKeywords(projectToSelect._id);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleError]);

  const fetchProjectKeywords = async (projectId) => {
    setIsKeywordsLoading(true);
    try {
      const response = await generalApi.keyword.getKeywordsByProjectId(
        projectId
      );
      setProjectKeywords(response.results);
    } catch (error) {
      handleError(error);
    } finally {
      setIsKeywordsLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  const copySelectedKeywords = () => {
    if (selectedKeywords.length === 0) {
      toast.warn("No keywords selected");
      return;
    }
  
    const keywordsToCopy = projectKeywords
      .filter((keyword) => selectedKeywords.includes(keyword._id))
      .map((keyword) => keyword.kw)
      .join("\n");
  
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(keywordsToCopy)
        .then(() => toast.success("Keywords copied to clipboard"))
        .catch(() => fallbackCopyTextToClipboard(keywordsToCopy));
    } else {
      fallbackCopyTextToClipboard(keywordsToCopy);
    }
  };

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        toast.success("Keywords copied to clipboard");
      } else {
        toast.error("Failed to copy keywords");
      }
    } catch (err) {
      toast.error("Failed to copy keywords");
    }

    document.body.removeChild(textArea);
  };

   const handleExportClick = (event) => {
     setExportAnchorEl(event.currentTarget);
   };

   const handleExportClose = () => {
     setExportAnchorEl(null);
   };

   const handleExport = (fileType) => {
     const headers = [
       "Keyword",
       "Volume",
       "Parasite Opportunity Score",
       "SERP Parasite Score",
       "Niche Category",
       "Updated",
     ];

     const data = (
       selectedKeywords.length > 0
         ? projectKeywords.filter((kw) => selectedKeywords.includes(kw._id))
         : projectKeywords
     ).map((keyword) => [
       keyword.kw,
       keyword.volume || "N/A",
       keyword.opportunityScore || "N/A",
       keyword.parasiteScore || "N/A",
       keyword.category || "N/A",
       new Date(keyword.updatedAt).toLocaleString(),
     ]);

     const csvContent = [
       headers.join(fileType === "csv" ? "," : "\t"),
       ...data.map((row) => row.join(fileType === "csv" ? "," : "\t")),
     ].join("\n");

     const blob = new Blob([csvContent], {
       type:
         fileType === "csv"
           ? "text/csv;charset=utf-8;"
           : "text/tab-separated-values;charset=utf-8;",
     });
     const link = document.createElement("a");
     if (link.download !== undefined) {
       const url = URL.createObjectURL(blob);
       link.setAttribute("href", url);
       link.setAttribute(
         "download",
         `${selectedProject.name}_keywords.${fileType}`
       );
       link.style.visibility = "hidden";
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
     }
     handleExportClose();
   };

  const handleAddOrUpdateProject = async (projectData) => {
    try {
      let response;
      if (projectData._id) {
        response = await generalApi.project.updateProject(projectData);
        setProjects((prevProjects) =>
          prevProjects.map((p) =>
            p._id === response.results._id ? response.results : p
          )
        );
        if (selectedProject && selectedProject._id === response.results._id) {
          setSelectedProject(response.results);
        }
        toast.success("Project updated successfully");
      } else {
        response = await generalApi.project.createProject(projectData);
        setProjects((prevProjects) => [...prevProjects, response.results]);
        toast.success("Project created successfully");
      }
      setIsProjectModalOpen(false);
      setEditingProject(null);
    } catch (error) {
      toast.error(
        projectData._id ? "Failed to update project" : handleError(error)
      );
    }
  };

  const handleDeleteProject = (project) => {
    setProjectToDelete(project);
    setDeleteConfirmOpen(true);
    handleProjectMenuClose();
  };

  const confirmDeleteProject = async () => {
    if (projectToDelete) {
      try {
        await generalApi.project.deleteProject(projectToDelete._id);
        setProjects((prevProjects) =>
          prevProjects.filter((p) => p._id !== projectToDelete._id)
        );
        if (selectedProject && selectedProject._id === projectToDelete._id) {
          setSelectedProject(null);
        }
        toast.success("Project deleted successfully");
      } catch (error) {
        toast.error("Failed to delete project");
      }
      setDeleteConfirmOpen(false);
      setProjectToDelete(null);
    }
  };

  const handleProjectMenuOpen = (event, project) => {
    setSelectedProject(project);
    setAnchorEl(event.currentTarget);
  };

  const handleProjectMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditProject = (project) => {
    const currentProject = projects.find((p) => p._id === project._id);
    setEditingProject(currentProject);
    setIsProjectModalOpen(true);
    handleProjectMenuClose();
  };

  const handleSelectKeyword = (keywordId) => {
    setSelectedKeywords((prev) =>
      Array.isArray(keywordId)
        ? keywordId
        : prev.includes(keywordId)
        ? prev.filter((id) => id !== keywordId)
        : [...prev, keywordId]
    );
  };

  const handleSelectProject = useCallback(
    async (project) => {
      setSelectedProject(project);
      await fetchProjectKeywords(project._id);
      navigate(`/projects?project=${project._id}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate]
  );

  const handleDeleteKeywords = () => {
    setIsDeleteKeywordConfirmOpen(true);
  };

  const confirmDeleteKeywords = async () => {
    if (!selectedProject || selectedKeywords.length === 0) {
      toast.error("No keywords selected for deletion");
      setIsDeleteKeywordConfirmOpen(false);
      return;
    }

    try {
      await generalApi.keyword.deleteKeywords(selectedKeywords);
      setProjectKeywords((prevKeywords) =>
        prevKeywords.filter((k) => !selectedKeywords.includes(k._id))
      );
      const updatedKeywordCount =
        selectedProject.keywordCount - selectedKeywords.length;
      setSelectedProject((prevProject) => ({
        ...prevProject,
        keywordCount: updatedKeywordCount,
      }));
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project._id === selectedProject._id
            ? { ...project, keywordCount: updatedKeywordCount }
            : project
        )
      );

      setSelectedKeywords([]);
      toast.success("Keywords deleted successfully");
    } catch (error) {
      handleError(error);
    }
    setIsDeleteKeywordConfirmOpen(false);
  };

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 164px)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        height: "calc(100vh - 164px)",
        bgcolor: "#121212",
      }}
    >
      {/* Left Panel - Projects List */}
      <Paper
        elevation={3}
        sx={{
          width: isMobile ? "100%" : 300,
          display: "flex",
          flexDirection: "column",
          height: isMobile ? "auto" : "100%",
          overflow: "hidden",
          bgcolor: "#0000005e",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" sx={{ color: "#fff" }}>
              Projects
            </Typography>
            <Tooltip title="Add Project">
              <IconButton
                onClick={() => setIsProjectModalOpen(true)}
                sx={{ color: "#fff" }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search projects..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: "#999" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                color: "#fff",
                "& fieldset": { borderColor: "#333" },
                "&:hover fieldset": { borderColor: "#444" },
                "&.Mui-focused fieldset": { borderColor: "#666" },
              },
            }}
          />
        </Box>
        {filteredProjects.length > 0 ? (
          <List
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              maxHeight: isMobile ? "300px" : "calc(100vh - 300px)",
              "&::-webkit-scrollbar": {
                width: "12px",
                backgroundColor: "#181818",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "6px",
                backgroundColor: "#9333EA",
              },
              "& .MuiTableRow-root:hover": {
                backgroundColor: "#222223",
              },
            }}
          >
            {filteredProjects.map((project) => (
              <ListItem
                key={project._id}
                button
                onClick={() => handleSelectProject(project)}
                selected={
                  selectedProject && selectedProject._id === project._id
                }
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#333",
                  },
                }}
              >
                <ListItemIcon>
                  <FolderIcon sx={{ color: "#fff", width: "35px" }} />
                </ListItemIcon>
                <Box
                  sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}
                >
                  <ReactCountryFlag
                    countryCode={project.country || "us"}
                    svg
                    style={{
                      width: "1.4em",
                      height: "1.4em",
                      marginRight: "10px",
                      paddingTop: "1px",
                    }}
                  />
                  <ListItemText
                    primary={project.name}
                    secondary={`${
                      project.keywordCount ? project.keywordCount : 0
                    } keywords`}
                    primaryTypographyProps={{ sx: { color: "#fff" } }}
                    secondaryTypographyProps={{ sx: { color: "#999" } }}
                  />
                </Box>
                <IconButton
                  edge="end"
                  aria-label="project options"
                  onClick={(event) => handleProjectMenuOpen(event, project)}
                  sx={{ color: "#fff" }}
                >
                  <MoreVertIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box sx={{ p: 2, color: "#999", textAlign: "center" }}>
            <Typography>No projects found.</Typography>
          </Box>
        )}
        <Divider
          sx={{
            my: 2,
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
        <Typography sx={{ color: "#fff", p: 2 }}>
          {filteredProjects.length}{" "}
          {filteredProjects.length === 1 ? "Project" : "Projects"}
        </Typography>
      </Paper>
  
      {/* Right Panel - Project Keywords */}
      <Box
        sx={{
          flexGrow: 1,
          pl: { xs: 0, sm: 2 },
          display: "flex",
          flexDirection: "column",
          height: isMobile ? "auto" : "calc(100vh - 164px)",
          overflow: "hidden",
          mt: { xs: 2, sm: 0 },
          width: isMobile ? '100%' : 'calc(100% - 300px)', // Subtract the width of the left panel
        }}
      >
        {selectedProject ? (
          <>
            {selectedKeywords.length > 0 && (
              <Box
                sx={{
                  color: "#fff",
                  p: 2,
                  borderRadius: 1,
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  bgcolor: " #1E1E1E",
                }}
              >
                <Typography variant="subtitle1">
                  {selectedKeywords.length} selected
                </Typography>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={handleDeleteKeywords}
                    sx={{ color: "#fff" }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            <Card
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#fff",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ReactCountryFlag
                      countryCode={selectedProject.country || "us"}
                      svg
                      style={{
                        width: "1.5em",
                        height: "1.5em",
                        marginRight: "10px",
                      }}
                    />
                    <span style={{ fontWeight: "bold", margin: "0 8px" }}>
                      {selectedProject.name}
                    </span>
                    Project Keywords
                  </Typography>
                  <Box>
                    <Tooltip title="Copy">
                      <IconButton
                        onClick={copySelectedKeywords}
                        sx={{ color: "#fff", mr: 1 }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Export">
                      <IconButton
                        onClick={handleExportClick}
                        sx={{ color: "#fff" }}
                      >
                        <GetAppIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      anchorEl={exportAnchorEl}
                      open={Boolean(exportAnchorEl)}
                      onClose={handleExportClose}
                    >
                      <MenuItem onClick={() => handleExport("csv")}>
                        Export as CSV
                      </MenuItem>
                      <MenuItem onClick={() => handleExport("tsv")}>
                        Export as TSV
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>
                <StyledTableContainerProjectKeyword>
                  {isKeywordsLoading ? (
                    <Box sx={{ 
                      height: { xs: 'auto', sm: 'calc(100vh - 300px)' },
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center' 
                    }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ProjectKeywordTable
                      keywords={projectKeywords}
                      selectedKeywords={selectedKeywords}
                      onSelectKeyword={handleSelectKeyword}
                      onDeleteKeywords={handleDeleteKeywords}
                      countryIsoCode={selectedProject.country}
                    />
                  )}
                </StyledTableContainerProjectKeyword>
              </CardContent>
            </Card>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography sx={{ color: "#999" }}>
              Select a project to view keywords
            </Typography>
          </Box>
        )}
      </Box>
  
      {/* Add/Edit Project Modal */}
      <AddProjectModal
        open={isProjectModalOpen}
        onClose={() => {
          setIsProjectModalOpen(false);
          setEditingProject(null);
        }}
        onSubmit={handleAddOrUpdateProject}
        project={editingProject}
      />
  
      {/* Project Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProjectMenuClose}
        PaperProps={{
          style: {
            backgroundColor: "#2C2C2C",
            color: "#fff",
          },
        }}
      >
        <MenuItem onClick={() => handleEditProject(selectedProject)}>
          <ListItemIcon>
            <EditIcon fontSize="small" sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleDeleteProject(selectedProject)}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
  
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        PaperProps={{
          style: {
            background: "#292929",
            color: "#fff",
          },
        }}
      >
        <DialogTitle>{"Delete Project"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#ccc" }}>
            Are you sure you want to delete this project? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmOpen(false)}
            color="secondary"
            sx={{ color: "#fff", mr: 1, bgcolor: "#595959" }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDeleteProject}
            color="primary"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
  
      {/* Delete Keyword Confirmation Dialog */}
      <Dialog
        open={isDeleteKeywordConfirmOpen}
        onClose={() => setIsDeleteKeywordConfirmOpen(false)}
        PaperProps={{
          style: {
            background: "#292929",
            color: "#fff",
          },
        }}
      >
        <DialogTitle>{"Delete Keywords"}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#ccc" }}>
            Are you sure you want to delete the selected keywords? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteKeywordConfirmOpen(false)}
            color="secondary"
            sx={{ color: "#fff", mr: 1, bgcolor: "#595959" }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDeleteKeywords}
            variant="contained"
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ProjectScreen;