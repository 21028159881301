import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  styled,
  CircularProgress,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import profileApi from "../api/profileApi";
import { useErrorHandler } from "../hooks/useErrorHandler";

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const InfoItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

function Profile() {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleError = useErrorHandler();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await profileApi.getProfile();
        setProfileData(data);
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [handleError]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!profileData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">
          Failed to load profile data. Please try again.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3, margin: "auto" }}>
      <Typography
        variant="h4"
        gutterBottom
        fontWeight="bold"
        color="text.primary"
      >
        Profile Settings
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <SectionTitle variant="h6">Basic Info</SectionTitle>
        <InfoItem>
          <Typography variant="subtitle1" color="text.secondary">
            Name
          </Typography>
          <Typography variant="body1">{profileData.name}</Typography>
        </InfoItem>
        <InfoItem>
          <Typography variant="subtitle1" color="text.secondary">
            Email
          </Typography>
          <Typography variant="body1">{profileData.email}</Typography>
        </InfoItem>
      </Paper>

      {/* <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <SectionTitle variant="h6">Manage Newsletter Settings</SectionTitle>
        <Typography paragraph color="text.secondary">
          You are currently{" "}
          {profileData.isNewsletterSubscriber ? "subscribed" : "not subscribed"}{" "}
          to our newsletter.
        </Typography>
        <StyledButton variant="contained" color="primary">
          {profileData.isNewsletterSubscriber ? "Unsubscribe" : "Subscribe"}
        </StyledButton>
      </Paper> */}

      <Paper elevation={3} sx={{ p: 3 }}>
        <SectionTitle variant="h6">Other Settings</SectionTitle>
        <Grid container spacing={2}>
          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              sx={{ color: "white" }}
              component={RouterLink}
              to="/reset-password"
            >
              Change password
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              sx={{ color: "white" }}
              component="a"
              href="https://parasiteseo.com/contact-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Request to delete account
            </StyledButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default Profile;
