import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import ReactCountryFlag from "react-country-flag";

const ProjectSelector = ({
  id,
  value,
  onChange,
  onAddProject,
  className,
  projects,
  required,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredProjects = projects.filter((project) =>
    project.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderProjectIcon = (project, isStartAdornment = false) => (
    <ListItemIcon
      style={{
        display: "flex",
        alignItems: "center",
        minWidth: "auto",
        marginRight: isStartAdornment ? "0" : "8px",
      }}
    >
      {project._id === "add_new" ? (
        <AddIcon />
      ) : (
        <>
          <FolderIcon style={{ marginRight: "5px" }} />
          <ReactCountryFlag
            countryCode={project.country}
            svg
            style={{
              marginRight: isStartAdornment ? "0" : "5px",
              height: "1.3em",
              width: "1.3em",
              paddingTop: "1px",
            }}
          />
        </>
      )}
    </ListItemIcon>
  );

  return (
    <FormControl fullWidth required={required}>
      <InputLabel id={`${id}-label`} shrink={true} sx={{ color: "#999" }}>
        Project
      </InputLabel>
      <Autocomplete
        id={id}
        value={value}
        onChange={(event, newValue) => {
          if (newValue && newValue._id === "add_new") {
            onAddProject();
          } else {
            onChange(newValue);
          }
        }}
        options={[
          ...filteredProjects,
          { _id: "add_new", name: "Add New Project", country: "" },
        ]}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Project"
            placeholder="Select or search project"
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {value ? renderProjectIcon(value, true) : <SearchIcon />}
                </InputAdornment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => {
          const { key, ...otherProps } = props;
          return (
            <MenuItem key={key} {...otherProps}>
              {renderProjectIcon(option)}
              <ListItemText primary={option.name} />
            </MenuItem>
          );
        }}
        className={className}
        sx={{
          backgroundColor: "#2C2C2C",
          color: "#fff",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#444",
            },
            "&:hover fieldset": {
              borderColor: "#666",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#9333EA",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#999",
            "&.Mui-focused": {
              color: "#9333EA",
            },
          },
          "& .MuiAutocomplete-input": {
            color: "#fff",
          },
          "& .MuiSvgIcon-root": {
            color: "#fff",
          },
          paddingLeft: 0,
        }}
      />
    </FormControl>
  );
};

export default ProjectSelector;
