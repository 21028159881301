import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, Button, Tooltip, Collapse, IconButton, LinearProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { ParasiteTypeIndicator, StyledTableContainer } from './TablesCommon';
import { toast } from 'react-toastify';
import { useLimits } from "../../context/LimitsContext";
import { Link } from 'react-router-dom';
import RedditOpportunityExpanded from './RedditOpportunityExpanded';

function ParasiteOpportunitiesTable({ opportunities }) {
  const [parasiteFilter, setParasiteFilter] = useState('all');
  const [expandedRows, setExpandedRows] = useState({});
  const { limits } = useLimits();
  const [filteredOpportunities, setFilteredOpportunities] = useState([]);
  const [isLoading, setIsLoading] = useState({});

  useEffect(() => {
    if (opportunities && limits) {
      let filtered = opportunities.filter(opportunity => {
        if (parasiteFilter === 'all') return true;
        if (parasiteFilter === 'free') return opportunity.type === 'free';
        if (parasiteFilter === 'paid') return opportunity.type === 'paid';
        return true;
      });
      setFilteredOpportunities(filtered);
    }
  }, [opportunities, parasiteFilter, limits]);

  const getCategoryColor = (score) => {
    if (score >= 50) return '#4ECB71';
    if (score >= 20) return '#F8D66D';
    if (score >= 10) return '#FFB54C';
    return '#FF6961';
  };

  const handleExpandClick = (domain) => {
    setExpandedRows(prev => ({ ...prev, [domain]: !prev[domain] }));
    if (domain === 'reddit.com') {
      setIsLoading(prev => ({ ...prev, [domain]: true }));
      setTimeout(() => {
        setIsLoading(prev => ({ ...prev, [domain]: false }));
      }, 1);
    }
  };

  const handleContactClick = (contact, type) => {
    if (type === 'free') {
      toast.info('No contact info available for free parasites');
    } else if (contact) {
      if (contact.includes('@')) {
        navigator.clipboard.writeText(contact);
        toast.success('Email copied to clipboard');
      } else if (contact.includes('http')) {
        window.open(contact, '_blank');
      }
    }
  };

  const isPaidDisabled = limits && (limits.subscriptionPlanTypeId === 0 || limits.subscriptionPlanTypeId === 1 || limits.subscriptionPlanTypeId === 11);
  const showPlanLimitMessage = limits && (limits.subscriptionPlanTypeId === 2 || limits.subscriptionPlanTypeId === 12) && 
                               parasiteFilter !== 'free';

  const paidParasiteCount = filteredOpportunities.filter(opp => opp.type === 'paid').length;
  const paidParasiteLimit = limits ? limits.planFeatures.paidParasiteLimit : 0;

  const renderExpandedContent = (opportunity) => {
    if (opportunity.domain === 'reddit.com') {
      return (
        <Box sx={{ padding: '20px 0', margin: '0 -15px' }}>
          {isLoading[opportunity.domain] ? (
            <LinearProgress sx={{ my: 2 }} />
          ) : (
            <RedditOpportunityExpanded opportunity={opportunity} />
          )}
        </Box>
      );
    }

    return (
      <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'row', maxWidth: '100%', overflowX: 'hidden' }}>
        <Box sx={{ width: '50%', pr: 2 }}>
          <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
            Parasite's Top Categories
            <Tooltip title="These are the top categories for this parasite domain, NOT relative to other parasite domains. Not to be confused with the Parasite Category Scores, which are relative to other domains in the category.">
              <InfoIcon
                fontSize="small"
                sx={{
                  marginLeft: 1,
                  verticalAlign: 'middle',
                  color: '#666',
                  cursor: 'pointer',
                  '&:hover': { color: '#fff' }
                }}
              />
            </Tooltip>
          </Typography>
          {opportunity.topCategories && opportunity.topCategories.map((category, catIndex) => (
            <Box key={catIndex} sx={{ mb: 1 }}>
              <Typography variant="caption" sx={{ color: '#fff', display: 'block' }}>
                {category.category}
              </Typography>
              <Box sx={{ width: '100%', maxWidth: '300px', bgcolor: '#333', borderRadius: 1, height: 10, overflow: 'hidden' }}>
                <Box
                  sx={{
                    width: `${category.score}%`,
                    bgcolor: getCategoryColor(category.score, catIndex === 0),
                    height: '100%',
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ width: '50%', pl: 2 }}>
          <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
            Contact
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: opportunity.type === 'paid' && opportunity.contact ? '#6DB9FF' : '#fff',
              cursor: opportunity.type === 'paid' && opportunity.contact && (opportunity.contact.includes('@') || opportunity.contact.includes('http')) ? 'pointer' : 'default',
              '&:hover': {
                textDecoration: opportunity.type === 'paid' && opportunity.contact && (opportunity.contact.includes('@') || opportunity.contact.includes('http')) ? 'underline' : 'none',
              },
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
            }}
            onClick={() => handleContactClick(opportunity.contact, opportunity.type)}
          >
            {opportunity.type === 'free'
              ? "No contact info available for free parasites"
              : (opportunity.contact || "No contact info to show")}
          </Typography>
        </Box>
      </Box>
    );
  };

  if (!opportunities || opportunities.length === 0) {
    return (
      <Typography sx={{ color: '#fff', textAlign: 'center', py: 2 }}>
        No parasite opportunities could be found.
      </Typography>
    );
  }

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, width: "100%" }}>
        <Typography variant="h6" sx={{ color: '#fff' }}>
          Parasite Opportunities
        </Typography>
        <Box>
          <Button
            variant={parasiteFilter === 'all' ? 'outlined' : 'text'}
            color="primary"
            onClick={() => setParasiteFilter('all')}
            sx={{
              mr: 1,
              borderColor: parasiteFilter === 'all' ? '#fff' : 'transparent',
              color: '#fff',
              opacity: parasiteFilter === 'all' ? 1 : 0.75
            }}
          >
            All
          </Button>
          <Button
            variant={parasiteFilter === 'free' ? 'contained' : 'text'}
            color="primary"
            onClick={() => setParasiteFilter('free')}
            sx={{
              mr: 1,
              bgcolor: parasiteFilter === 'free' ? '#4ECB71' : 'transparent',
              color: '#fff',
              opacity: parasiteFilter === 'free' ? 1 : 0.75
            }}
          >
            Free
          </Button>
          <Tooltip title={isPaidDisabled ? "Paid parasite opportunities are not available on your plan. Please upgrade to unlock them." : ""}>
            <span>
              <Button
                variant={parasiteFilter === 'paid' ? 'contained' : 'text'}
                color="primary"
                onClick={() => !isPaidDisabled && setParasiteFilter('paid')}
                disabled={isPaidDisabled}
                sx={{
                  bgcolor: parasiteFilter === 'paid' ? '#9333EA' : 'transparent',
                  color: '#fff',
                  opacity: parasiteFilter === 'paid' ? 1 : 0.75
                }}
              >
                Paid
                {isPaidDisabled && <LockIcon sx={{ ml: 1, fontSize: 16 }} />}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
      <StyledTableContainer>
        <Table stickyHeader sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: '#2C2C2C', color: '#fff', width: '60%', minWidth: '200px', paddingLeft: '30px' }}>Domain</TableCell>
              <TableCell sx={{ bgcolor: '#2C2C2C', color: '#fff', width: '60px', minWidth: '60px', textAlign: 'center' }}>Type</TableCell>
              <TableCell sx={{ bgcolor: '#2C2C2C', color: '#fff', width: '70%', minWidth: '300px' }}>Parasite Category Scores</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOpportunities.map((opportunity, index) => (
              <React.Fragment key={index}>
                <TableRow sx={{ '&:nth-of-type(odd)': { bgcolor: '#252525' } }}>
                  <TableCell sx={{ color: '#fff', position: 'relative', paddingLeft: '40px' }}>
                    <IconButton
                      onClick={() => handleExpandClick(opportunity.domain)}
                      sx={{
                        color: '#fff',
                        padding: '4px',
                        position: 'absolute',
                        left: '8px',
                        top: '50%',
                        transform: 'translateY(-50%)'
                      }}
                    >
                      <ChevronRightIcon sx={{
                        transform: expandedRows[opportunity.domain] ? 'rotate(90deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s'
                      }} />
                    </IconButton>
                    <Box sx={{ paddingLeft: '10px' }}>
                      <Typography
                        component="a"
                        href={`https://${opportunity.domain}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: '#fff',
                          textDecoration: 'none',
                          '&:hover': {
                            color: '#6DB9FF',
                            textDecoration: 'underline',
                          },
                          wordBreak: 'break-word',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {opportunity.title}
                      </Typography>
                      {opportunity.info && (
                        <Tooltip title={opportunity.info}>
                          <InfoIcon
                            fontSize="small"
                            sx={{
                              marginLeft: 1,
                              verticalAlign: 'middle',
                              color: '#666',
                              cursor: 'pointer',
                              '&:hover': { color: '#fff' }
                            }}
                          />
                        </Tooltip>
                      )}
                      <Typography variant="body2" sx={{ color: '#999', mt: 0.5 }}>
                        {opportunity.domain}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center', paddingLeft: 0, paddingRight: 0 }}>
                    <Tooltip title={`${opportunity.type === 'free' ? 'Free' : 'Paid'} Parasite Domain`}>
                      <span>
                        <ParasiteTypeIndicator type={opportunity.type} />
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {opportunity.categories.map((category, catIndex) => (
                      <Box key={catIndex} sx={{ mb: 1 }}>
                        <Typography variant="caption" sx={{ color: '#fff', display: 'block' }}>
                          {category.name}
                        </Typography>
                        <Box sx={{ width: '100%', maxWidth: '300px', bgcolor: '#333', borderRadius: 1, height: 10, overflow: 'hidden' }}>
                          <Box
                            sx={{
                              width: `${category.score}%`,
                              bgcolor: getCategoryColor(category.score, catIndex === 0),
                              height: '100%',
                            }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                    <Collapse in={expandedRows[opportunity.domain]} timeout="auto" unmountOnExit>
                      {renderExpandedContent(opportunity)}
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        {showPlanLimitMessage && paidParasiteCount >= paidParasiteLimit && (
          <Box sx={{ textAlign: 'center', py: 3, bgcolor: '#252525' }}>
            <Typography sx={{ color: '#fff', mb: 2 }}>
              Plan limit reached. Please upgrade.
            </Typography>
            <Button
              component={Link}
              to="/subscription"
              target="_blank"
              variant="contained"
              color="primary"
            >
              Upgrade
            </Button>
          </Box>
        )}
      </StyledTableContainer>
    </Box>
  );
}

export default ParasiteOpportunitiesTable;