import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon, PictureAsPdf as PdfIcon } from '@mui/icons-material';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import logo from "../../assets/parasite_seo.png";
import styles from './AiOverviewModal.module.css';

const AiOverviewModal = ({ open, onClose, data, parasiteOpportunities, dashboardKeyword, categories }) => {
  if (!data) {
    return null;
  }

  const exportToPdf = () => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4',
    });
  
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 40;
    const contentWidth = pageWidth - 2 * margin;
  
    const addBackground = () => {
      doc.setFillColor(41, 41, 41);
      doc.rect(0, 0, pageWidth, pageHeight, 'F');
    };
  
    doc.addPage = (function(addPage) {
      return function() {
        const result = addPage.apply(this, arguments);
        addBackground();
        return result;
      };
    })(doc.addPage);
  
    addBackground();
  
    const imgWidth = 200;
    const img = new Image();
    img.src = logo;
    img.onload = function() {
      const aspectRatio = this.height / this.width;
      const imgHeight = imgWidth * aspectRatio;
      doc.addImage(logo, 'PNG', (pageWidth - imgWidth) / 2, margin, imgWidth, imgHeight);
      
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(22);
      let yPos = imgHeight + margin + 40;
      
      const titleLines = doc.splitTextToSize(`Parasite SEO Analysis for keyword: "${dashboardKeyword}"`, contentWidth);
      doc.text(titleLines, pageWidth / 2, yPos, { align: 'center' });
      yPos += titleLines.length * 14 + 20;
      
      const addSection = (title, content) => {
        if (content) {
          doc.setFontSize(14);
          doc.setTextColor(177, 249, 56);
          doc.text(title, margin, yPos);
          yPos += 20;
          doc.setFontSize(12);
          doc.setTextColor(255, 255, 255);
          const lines = doc.splitTextToSize(content, contentWidth);
          doc.text(lines, margin, yPos);
          yPos += lines.length * 14 + 20;

          if (yPos > pageHeight - margin) {
            doc.addPage();
            yPos = margin;
          }
        }
      };
      
      addSection('Keyword Description', data.keywordDescription);
      addSection('SERP Description', data.serpDescription);
      addSection('Recommended Parasite Strategy', data.recommendedParasiteStrategy);
  
      if (categories) {
        const secondaryCategories = categories.categories
          ?.filter(cat => cat.category !== categories.primaryCategory)
          .map(cat => cat.category) || [];
        addSection('Categories', 
          `Primary: ${categories.primaryCategory || 'N/A'}\n\n` +
          `Secondary: ${secondaryCategories.join(', ') || 'N/A'}\n\n` +
          `Subcategories: ${categories.subcategories?.join(', ') || 'N/A'}`
        );
      }
      
      const tableOptions = {
        styles: { 
          cellPadding: 5,
          fontSize: 10,
          textColor: [255, 255, 255],
          fillColor: [51, 51, 51],
          overflow: 'linebreak',
        },
        headStyles: { fillColor: [68, 68, 68], fontStyle: 'bold' },
        alternateRowStyles: { fillColor: [60, 60, 60] },
        margin: { top: 20, right: margin, bottom: 20, left: margin },
      };
  
      const addTableWithHeading = (heading, tableData) => {
        if (yPos > pageHeight - 100) {
          doc.addPage();
          yPos = margin;
        }

        doc.setFontSize(14);
        doc.setTextColor(177, 249, 56);
        doc.text(heading, margin, yPos);
        yPos += 25;
  
        doc.autoTable({
          ...tableOptions,
          ...tableData,
          startY: yPos,
        });
        yPos = doc.lastAutoTable.finalY + 30;
      };
      
      if (data.freeParasitesWithTitlesAndDescriptions?.length > 0) {
        addTableWithHeading('Free Parasites', {
          head: [['Domain', 'Why It Could Outrank', 'Example Title', 'Example Description']],
          body: data.freeParasitesWithTitlesAndDescriptions.map(parasite => [
            parasite.parasiteDomain,
            parasite.whyItCouldOutrankCurrent,
            parasite.exampleArticleTitle,
            parasite.exampleArticleDescription,
          ]),
        });
      }
  
      if (data.paidParasitesWithTitlesAndDescriptions?.length > 0) {
        addTableWithHeading('Paid Parasites', {
          head: [['Domain', 'Why It Could Outrank', 'Example Title', 'Example Description']],
          body: data.paidParasitesWithTitlesAndDescriptions.map(parasite => [
            parasite.parasiteDomain,
            parasite.whyItCouldOutrankCurrent,
            parasite.exampleArticleTitle,
            parasite.exampleArticleDescription,
          ]),
        });
      }
        
      if (data.contentBrief?.length > 0) {
        addTableWithHeading('Content Brief', {
          head: [['H2', 'H3s']],
          body: data.contentBrief.map(section => [
            section.h2,
            section.h3s.join(', ')
          ]),
        });
      }
      
      const timestampDate = new Date().toISOString().slice(0, 10);
      doc.save(`${dashboardKeyword}_Parasite_SEO_Analysis_${timestampDate}.pdf`);
    };
    img.src = logo;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          backgroundColor: '#292929',
          color: '#fff',
          height: '90vh',
        },
      }}
    >
      <DialogTitle className={styles.dialogTitle}>
        AI Overview (Experimental)
        <Box>
          <IconButton onClick={exportToPdf} color="inherit">
            <PdfIcon />
          </IconButton>
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Typography variant="h5" gutterBottom>AI Overview for Keyword: {dashboardKeyword}</Typography>
        {data.keywordDescription && (
          <Typography variant="body1" paragraph>{data.keywordDescription}</Typography>
        )}
        
        {data.serpDescription && (
          <>
            <Typography variant="h6" gutterBottom>SERP Description</Typography>
            <Typography variant="body1" paragraph>{data.serpDescription}</Typography>
          </>
        )}

        {data.recommendedParasiteStrategy && (
          <>
            <Typography variant="h6" gutterBottom>Recommended Parasite Strategy</Typography>
            <Typography variant="body1" paragraph>{data.recommendedParasiteStrategy}</Typography>
          </>
        )}

        {categories && (
          <>
            <Typography variant="h6" gutterBottom>Categories</Typography>
            <Typography variant="body1" paragraph>
              Primary: {categories.primaryCategory || 'N/A'}<br />
              Secondary: {categories.categories
                ?.filter(cat => cat.category !== categories.primaryCategory)
                .map(cat => cat.category)
                .join(', ') || 'N/A'}<br />
              Subcategories: {categories.subcategories?.join(', ') || 'N/A'}
            </Typography>
          </>
        )}
        
        {data.freeParasitesWithTitlesAndDescriptions?.length > 0 && (
          <>
            <Typography variant="h6" gutterBottom style={{marginTop: '20px'}}>Free Parasites</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Domain</TableCell>
                    <TableCell>Why It Could Outrank</TableCell>
                    <TableCell>Example Title</TableCell>
                    <TableCell>Example Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.freeParasitesWithTitlesAndDescriptions.map((parasite, index) => (
                    <TableRow key={index}>
                      <TableCell>{parasite.parasiteDomain}</TableCell>
                      <TableCell>{parasite.whyItCouldOutrankCurrent}</TableCell>
                      <TableCell>{parasite.exampleArticleTitle}</TableCell>
                      <TableCell>{parasite.exampleArticleDescription}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {data.paidParasitesWithTitlesAndDescriptions?.length > 0 && (
          <>
            <Typography variant="h6" gutterBottom style={{marginTop: '20px'}}>Paid Parasites</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Domain</TableCell>
                    <TableCell>Why It Could Outrank</TableCell>
                    <TableCell>Example Title</TableCell>
                    <TableCell>Example Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.paidParasitesWithTitlesAndDescriptions.map((parasite, index) => (
                    <TableRow key={index}>
                      <TableCell>{parasite.parasiteDomain}</TableCell>
                      <TableCell>{parasite.whyItCouldOutrankCurrent}</TableCell>
                      <TableCell>{parasite.exampleArticleTitle}</TableCell>
                      <TableCell>{parasite.exampleArticleDescription}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {data.contentBrief?.length > 0 && (
          <>
            <Typography variant="h6" gutterBottom style={{marginTop: '20px'}}>Content Brief</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>H2</TableCell>
                    <TableCell>H3s</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.contentBrief.map((section, index) => (
                    <TableRow key={index}>
                      <TableCell>{section.h2}</TableCell>
                      <TableCell>{section.h3s.join(', ')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AiOverviewModal;