import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  Grid,
  Button,
  Autocomplete,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Chip,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import { Search as SearchIcon, Info as InfoIcon, Lock as LockIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import ParasiteTable from "./ParasiteTable";
import styles from "./CategoryDashboard.module.css";
import generalApi from "../../api/generalApi";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useLimits } from "../../context/LimitsContext";
import { Link } from 'react-router-dom';

const CategoryDashboard = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [parasiteData, setParasiteData] = useState({ free: [], paid: [] });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleError = useErrorHandler();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { limits } = useLimits();
  const [expandedRows, setExpandedRows] = useState({});

  const popularCategories = [
    "Finance & Banking",
    "Crypto",
    "Dating",
    "Supplements",
    "CBD & Cannabis",
  ];

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      setError(null);
      console.log("Fetching categories...");
      const response = await generalApi.category.getTrendingCategories();
      console.log("API Response:", response);

      let fetchedCategories = [];
      if (Array.isArray(response)) {
        fetchedCategories = response;
      } else if (response && Array.isArray(response.results?.categoryNames)) {
        fetchedCategories = response.results.categoryNames;
      } else {
        throw new Error("Unexpected response format");
      }

      console.log("Fetched categories:", fetchedCategories);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
      handleError(error);
      setError("Failed to load categories. Please try again.");
      setCategories([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (category = selectedCategory) => {
    if (category) {
      setLoading(true);
      setError(null);
      try {
        console.log("Searching for category:", category);
        const data = await generalApi.category.getParasiteCategory(category);
        console.log("Search results:", data);
        if (data && data.results && data.results.result) {
          setParasiteData(data.results.result);
        } else {
          throw new Error("Unexpected response format");
        }
        setSelectedCategory(category);
        setInputValue(category);
      } catch (error) {
        console.error("Error in search:", error);
        handleError(error);
        setError("Failed to fetch parasite data. Please try again.");
        setParasiteData({ free: [], paid: [] });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleChipClick = (category) => {
    handleSearch(category);
  };

  const handleExpandClick = (domain) => {
    setExpandedRows(prev => ({ ...prev, [domain]: !prev[domain] }));
  };

  const isPaidDisabled = limits && (limits.subscriptionPlanTypeId === 0 || limits.subscriptionPlanTypeId === 1 || limits.subscriptionPlanTypeId === 11);

  return (
    <Box className={styles.dashboard}>
      <Box
        className={
          selectedCategory ? styles.searchBarTop : styles.searchBarCenter
        }
      >
        <Card className={styles.searchCard}>
          <CardContent className={styles.cardContent}>
            <Grid
              container
              spacing={isMobile ? 1 : 2}
              alignItems="center"
              sx={{ margin: 0, width: "100%" }}
            >
              <Grid
                item
                xs={12}
                md={9}
                sx={{
                  paddingLeft: "0 !important",
                  marginBottom: isMobile ? 1 : 0,
                }}
                className={styles.inputGrid}
              >
                <Autocomplete
                  options={categories}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search categories"
                      variant="outlined"
                      onKeyPress={handleKeyPress}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <SearchIcon className={styles.searchIcon} />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  value={selectedCategory}
                  onChange={(event, newValue) => {
                    setSelectedCategory(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  className={styles.categoryInput}
                  ListboxProps={{
                    className: styles.customScrollbar,
                  }}
                  componentsProps={{
                    popper: {
                      className: styles.customScrollbar,
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ paddingRight: "0 !important" }}
                className={styles.inputGrid}
              >
                <Button
                  variant="contained"
                  startIcon={
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <SearchIcon />
                    )
                  }
                  onClick={() => handleSearch()}
                  fullWidth
                  className={styles.searchButton}
                  disabled={!selectedCategory || loading}
                  sx={{ height: isMobile ? "40px" : "56px" }}
                >
                  {loading ? "SEARCHING" : "SEARCH"}
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1, alignItems: "center" }}>
              <Box
                sx={{
                  color: "#fff",
                  mr: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Popular:
                <Tooltip title="The trending categories are US-focused. We are adding more countries in the coming days!" arrow>
                  <IconButton size="small" sx={{ color: "#fff" }}>
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              {popularCategories.map((category) => (
                <Chip
                  key={category}
                  label={category}
                  onClick={() => handleChipClick(category)}
                  sx={{
                    backgroundColor: "#9333EA",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#7B2CBF",
                    },
                  }}
                />
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>

      {error && (
        <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
          {error}
        </Typography>
      )}

      {selectedCategory &&
        (parasiteData.free.length > 0 || parasiteData.paid.length > 0) && (
          <Box className={styles.resultsContainer}>
            <Grid container spacing={3} className={styles.parasiteTables}>
              <Grid item xs={12} md={6}>
                <Card sx={{ height: '100%' }}>
                  <CardContent sx={{ paddingBottom: "16px !important", height: '100%' }}>
                    <ParasiteTable
                      parasites={parasiteData.free}
                      category={selectedCategory}
                      type="Free"
                      expandedRows={expandedRows}
                      handleExpandClick={handleExpandClick}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card sx={{ height: '100%' }}>
                  <CardContent sx={{ paddingBottom: "16px !important", height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {isPaidDisabled ? (
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        height: '100%',
                        textAlign: 'center'
                      }}>
                        <LockIcon sx={{ fontSize: 48, color: '#666' }} />
                        <Typography sx={{ color: '#fff', mt: 2, mb: 2 }}>
                          Paid parasites are not available on your current plan.
                        </Typography>
                        <Button
                          component={Link}
                          to="/subscription"
                          target="_blank"
                          variant="contained"
                          color="primary"
                        >
                          Upgrade
                        </Button>
                      </Box>
                    ) : (
                      <ParasiteTable
                        parasites={parasiteData.paid}
                        category={selectedCategory}
                        type="Paid"
                        expandedRows={expandedRows}
                        handleExpandClick={handleExpandClick}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
    </Box>
  );
};

export default CategoryDashboard;