// src/config/env.js

const dev = {
    API_URL: 'http://localhost:3000/api',
    WP_BILLING_URL: 'https://billing.parasiteseo.com/',
    SOCKET_BE_URL: 'http://localhost:3000'
  };
  
  const prod = {
    API_URL: "https://app-be.parasiteseo.com/api",
    WP_BILLING_URL: "https://billing.parasiteseo.com/",
    SOCKET_BE_URL: "https://app-be.parasiteseo.com/api",
  };
  
  const config = process.env.NODE_ENV === 'production' ? prod : dev;
  
  export default config;