import React, { useState, useEffect } from "react";
import {
  Badge,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Divider,
  Tab,
  Tabs,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import {
  Notifications as NotificationsIcon,
  Circle as CircleIcon,
  ArrowForward as ArrowForwardIcon,
  Work as WorkIcon,
  Campaign as CampaignIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import generalApi from "../api/generalApi";
import { useErrorHandler } from "../hooks/useErrorHandler";

const Notifications = ({ notifications, setNotifications }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalJobNotifications, setTotalJobNotifications] = useState(0);
  const [totalMarketingNotifications, setTotalMarketingNotifications] =
    useState(0);
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async (newPage = 1) => {
    try {
      setLoading(true);
      const response = await generalApi.notifications.getNotifications(newPage);
      if (response && response.results) {
        const {
          jobNotifications = [],
          marketingNotifications = [],
          currentPage,
          totalJobNotifications,
          totalMarketingNotifications,
        } = response.results;

        if (newPage === 1) {
          setNotifications({
            jobNotifications,
            marketingNotifications,
          });
        } else {
          setNotifications((prev) => ({
            jobNotifications: [...prev.jobNotifications, ...jobNotifications],
            marketingNotifications: [
              ...prev.marketingNotifications,
              ...marketingNotifications,
            ],
          }));
        }

        setCurrentPage(currentPage);
        setTotalJobNotifications(totalJobNotifications);
        setTotalMarketingNotifications(totalMarketingNotifications);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (noti, type) => {
    try {
      if (type === "job") {
        await generalApi.notifications.markNotificationAsSeen(noti._id);
        setNotifications((prevNotifications) => ({
          ...prevNotifications,
          jobNotifications: prevNotifications.jobNotifications.map(
            (notification) =>
              notification._id === noti._id
                ? { ...notification, seenStatus: true }
                : notification
          ),
        }));
        handleClose();
        navigate(noti.url);
      } else {
        window.open(noti.url, "_blank");
        await generalApi.notifications.markGenaralNotificationAsSeen(noti._id);
        setNotifications((prevNotifications) => ({
          ...prevNotifications,
          marketingNotifications: prevNotifications.marketingNotifications.map(
            (notification) =>
              notification._id === noti._id
                ? { ...notification, seenStatus: true }
                : notification
          ),
        }));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const toggleSeenStatus = async (notification, type) => {
    try {
      const newSeenStatus = !notification.seenStatus;
      if (type === "job") {
        await generalApi.notifications.toggleNotificationSeenStatus(notification._id, newSeenStatus);
        setNotifications((prevNotifications) => ({
          ...prevNotifications,
          jobNotifications: prevNotifications.jobNotifications.map(
            (n) => n._id === notification._id ? { ...n, seenStatus: newSeenStatus } : n
          ),
        }));
      } else {
        await generalApi.notifications.toggleGeneralNotificationSeenStatus(notification._id, newSeenStatus);
        setNotifications((prevNotifications) => ({
          ...prevNotifications,
          marketingNotifications: prevNotifications.marketingNotifications.map(
            (n) => n._id === notification._id ? { ...n, seenStatus: newSeenStatus } : n
          ),
        }));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const unseenMarketingCount = notifications.marketingNotifications.filter(
    (n) => !n.seenStatus
  ).length;
  const unseenJobCount = notifications.jobNotifications.filter(
    (n) => !n.seenStatus
  ).length;
  const totalUnseenCount = unseenJobCount + unseenMarketingCount;

  const open = Boolean(anchorEl);
  const id = open ? "notifications-popover" : undefined;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const markAllAsRead = async () => {
    try {
      if (activeTab === 0) {
        await generalApi.notifications.markAllMarketingNotificationsAsRead();
        setNotifications((prevNotifications) => ({
          ...prevNotifications,
          marketingNotifications: prevNotifications.marketingNotifications.map(
            (n) => ({ ...n, seenStatus: true })
          ),
        }));
      } else {
        await generalApi.notifications.markAllJobNotificationsAsRead();
        setNotifications((prevNotifications) => ({
          ...prevNotifications,
          jobNotifications: prevNotifications.jobNotifications.map((n) => ({
            ...n,
            seenStatus: true,
          })),
        }));
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getNotificationIcon = (type, entityType) => {
    if (type === "job") {
      return (
        <WorkIcon
          sx={{
            color: "#fff",
            bgcolor: "#9333EA",
            p: 1,
            borderRadius: "50%",
            width: 32,
            height: 32,
            mr: 1.5,
          }}
        />
      );
    } else {
      return (
        <CampaignIcon
          sx={{
            color: "#fff",
            bgcolor: "#4ECB71",
            p: 1,
            borderRadius: "50%",
            width: 32,
            height: 32,
            mr: 1.5,
          }}
        />
      );
    }
  };

  const handleLoadMore = () => {
    fetchNotifications(currentPage + 1);
  };

  const showLoadMore = () => {
    if (activeTab === 0) {
      return (
        totalMarketingNotifications > 30 &&
        notifications.marketingNotifications.length < totalMarketingNotifications
      );
    } else {
      return (
        totalJobNotifications > 30 &&
        notifications.jobNotifications.length < totalJobNotifications
      );
    }
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="notifications"
        onClick={handleClick}
      >
        <Badge badgeContent={totalUnseenCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            width: 360,
            maxHeight: 480,
            backgroundColor: "#292929",
            color: "#fff",
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
            overflow: "hidden",
          },
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "#2C2C2C",
            borderBottom: "1px solid #444",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              "& .MuiTab-root": {
                color: "#fff",
                "&.Mui-selected": {
                  color: "#9333EA",
                },
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#9333EA",
              },
            }}
          >
            <Tab
              label={`Updates${
                unseenMarketingCount > 0 ? ` (${unseenMarketingCount})` : ""
              }`}
              sx={{ width: "50%" }}
            />
            <Tab
              label={`Tasks${unseenJobCount > 0 ? ` (${unseenJobCount})` : ""}`}
              sx={{ width: "50%" }}
            />
          </Tabs>
        </Box>
        <List sx={{ py: 0, maxHeight: 380, overflowY: "auto" }}>
          {activeTab === 0 ? (
            notifications.marketingNotifications.length > 0 ? (
              notifications.marketingNotifications.map((notification, index) => (
                <React.Fragment key={notification._id}>
                  <ListItem
                    sx={{
                      py: 1,
                      px: 2,
                      "&:hover": {
                        backgroundColor: "rgba(147, 51, 234, 0.1)",
                      },
                    }}
                  >
                    {getNotificationIcon("marketing")}
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "medium",
                            mb: 0.25,
                            fontSize: "0.9rem",
                          }}
                        >
                          {notification.title}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#999",
                              display: "block",
                              mb: 0.25,
                              fontSize: "0.8rem",
                            }}
                          >
                            {notification.content}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ color: "#B0B0B0", fontSize: "0.75rem" }}
                          >
                            {formatDate(notification.createdAt)}
                          </Typography>
                        </>
                      }
                    />
                    <Tooltip title={notification.seenStatus ? "Mark as not Seen" : "Mark as Seen"}>
  <IconButton
    onClick={(e) => {
      e.stopPropagation();
      toggleSeenStatus(notification, activeTab === 0 ? "marketing" : "job");
    }}
    sx={{ mr: 1 }}
  >
    {notification.seenStatus ? (
      <VisibilityOffIcon sx={{ color: "#999" }} />
    ) : (
      <VisibilityIcon sx={{ color: activeTab === 0 ? "#4ECB71" : "#9333EA" }} />
    )}
  </IconButton>
</Tooltip>
                    <IconButton
                      onClick={() => handleNotificationClick(notification, "marketing")}
                    >
                      <ArrowForwardIcon sx={{ color: "#666" }} />
                    </IconButton>
                  </ListItem>
                  {index < notifications.marketingNotifications.length - 1 && (
                    <Divider sx={{ backgroundColor: "#444" }} />
                  )}
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="body2"
                      color="#999"
                      align="center"
                      sx={{ py: 2 }}
                    >
                      No new updates notifications
                    </Typography>
                  }
                />
              </ListItem>
            )
          ) : notifications.jobNotifications.length > 0 ? (
            notifications.jobNotifications.map((notification, index) => (
              <React.Fragment key={notification._id}>
                <ListItem
                  sx={{
                    py: 1,
                    px: 2,
                    "&:hover": {
                      backgroundColor: "rgba(147, 51, 234, 0.1)",
                    },
                  }}
                >
                  {getNotificationIcon("job", notification.entityType)}
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "medium",
                          mb: 0.25,
                          fontSize: "0.9rem",
                        }}
                      >
                        {notification.title}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#999",
                            display: "block",
                            mb: 0.25,
                            fontSize: "0.8rem",
                          }}
                        >
                          {`Status: ${notification.entityStatus}`}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ color: "#B0B0B0", fontSize: "0.75rem" }}
                        >
                          {formatDate(notification.createdAt)}
                        </Typography>
                      </>
                    }
                  />
                <Tooltip title={notification.seenStatus ? "Mark as Not Seen" : "Mark as Seen"}>
  <IconButton
    onClick={(e) => {
      e.stopPropagation();
      toggleSeenStatus(notification, "job");
    }}
    sx={{ mr: 1 }}
  >
    {notification.seenStatus ? (
      <VisibilityOffIcon sx={{ color: "#999" }} />
    ) : (
      <VisibilityIcon sx={{ color: "#9333EA" }} />
    )}
  </IconButton>
</Tooltip>
                  <IconButton
                    onClick={() => handleNotificationClick(notification, "job")}
                  >
                    <ArrowForwardIcon sx={{ color: "#666" }} />
                  </IconButton>
                </ListItem>
                {index < notifications.jobNotifications.length - 1 && (
                  <Divider sx={{ backgroundColor: "#444" }} />
                )}
              </React.Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    color="#999"
                    align="center"
                    sx={{ py: 2 }}
                  >
                    No new tasks notifications
                  </Typography>
                }
              />
            </ListItem>
          )}
          {showLoadMore() && (
            <ListItem
              button
              onClick={handleLoadMore}
              disabled={loading}
              sx={{
                justifyContent: "center",
                color: "#9333EA",
                marginBottom: "10px",
                "&:hover": {
                  backgroundColor: "rgba(147, 51, 234, 0.1)",
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Load More"
              )}
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};

export default Notifications;