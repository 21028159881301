import React from "react";
import {
  Box,
  Typography,
  Button,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import { useLimits } from "../context/LimitsContext";
import {
  ArrowUpward as ArrowUpwardIcon,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import { Link } from 'react-router-dom';

const SidenavCredits = ({ isExpanded }) => {
  const { limits } = useLimits();

  if (!limits) return null;

  const { subscriptionPlanTypeId, planName, lookupCredits, aiCredits, planFeatures, nextMonthlyResetDate } = limits;

  const formatCredits = (used, total) => `${used}/${total}`;

  const calculatePercentage = (used, total) => (used / total) * 100;

  const formatResetDate = (date) => {
    if (!date) return "N/A";
    const resetDate = new Date(date);
    return `${resetDate.toDateString()}, midnight GMT`;
  };

  return (
    <Box
      sx={{
        p: 2,
        borderTop: "1px solid rgba(255,255,255,0.1)",
        backgroundColor: "rgba(28, 28, 28, 0.8)",
        backdropFilter: "blur(5px)",
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {isExpanded && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "white", fontWeight: "bold" }}
            >
              Current Plan
            </Typography>
            <Typography variant="body2" sx={{ color: "white" }}>
              {planName ? planName : "Free/Cancelled"}
            </Typography>
          </Box>
          {subscriptionPlanTypeId < 13 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                component={Link}
                to="/subscription"
                variant="contained"
                size="small"
                startIcon={<ArrowUpwardIcon />}
                sx={{
                  mb: 2,
                  width: "80%",
                  backgroundColor: (theme) => theme.palette.primary.main,
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.primary.dark,
                  },
                }}
              >
                Upgrade
              </Button>
            </Box>
          )}
          <Tooltip
            title={`Next monthly credit refresh: ${formatResetDate(nextMonthlyResetDate)}`}
            placement="top"
          >
            <Typography
              variant="subtitle2"
              sx={{
                color: "white",
                fontWeight: "bold",
                mb: 1,
                display: "flex",
                alignItems: "center",
                cursor: "help",
              }}
            >
              Credits <RefreshIcon fontSize="small" sx={{ ml: 1 }} />
            </Typography>
          </Tooltip>
          <Box sx={{ mb: 1 }}>
            <Typography
              variant="body2"
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Analysis Credits:</span>
              <span>
                {formatCredits(lookupCredits.used, lookupCredits.daily)}
              </span>
            </Typography>
            <LinearProgress
              variant="determinate"
              value={calculatePercentage(
                lookupCredits.used,
                lookupCredits.daily
              )}
              sx={{
                mt: 0.5,
                backgroundColor: "rgba(255,255,255,0.1)",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: (theme) => theme.palette.secondary.main,
                },
              }}
            />
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "white",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>AI Credits:</span>
              <span>{formatCredits(aiCredits.used, aiCredits.monthly)}</span>
            </Typography>
            <LinearProgress
              variant="determinate"
              value={calculatePercentage(aiCredits.used, aiCredits.monthly)}
              sx={{
                mt: 0.5,
                backgroundColor: "rgba(255,255,255,0.1)",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: (theme) => theme.palette.success.main,
                },
              }}
            />
          </Box>
        </>
      )}
      {!isExpanded && (
        <Tooltip
          title={`Credits | Next monthly reset date: ${formatResetDate(nextMonthlyResetDate)}`}
          placement="right"
        >
          <Box
            sx={{
              width: "100%",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "white",
                writingMode: "vertical-rl",
                textOrientation: "mixed",
                transform: "rotate(180deg)",
              }}
            >
              Credits
            </Typography>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default SidenavCredits;