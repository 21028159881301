import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Container,
  Divider,
  IconButton,
  Alert,
} from "@mui/material";
import { Check as CheckIcon, Close as CloseIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import authApi from "../api/authApi";
import { useErrorHandler } from "../hooks/useErrorHandler";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

function ResetPasswordForm() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(false);
  const [tokenVerificationLoading, setTokenVerificationLoading] =
    useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tokenError, setTokenError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  const passwordRequirements = [
    { regex: /.{10,}/, label: "At least 10 characters long" },
    { regex: /[A-Z]/, label: "At least one uppercase letter" },
    { regex: /[a-z]/, label: "At least one lowercase letter" },
    { regex: /[0-9]/, label: "At least one number" },
  ];

  const [requirements, setRequirements] = useState(
    passwordRequirements.map((req) => ({ ...req, met: false }))
  );

  useEffect(() => {
    setIsLoggedIn(authApi.checkAuthStatus());
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    const token = searchParams.get("passwordResetToken");
    if (email && token) {
      verifyToken(email, token);
    } else {
      setTokenVerificationLoading(false);
      setTokenError("Invalid reset password link. Please request a new one.");
    }
  }, [location, navigate]);

  useEffect(() => {
    setRequirements(
      passwordRequirements.map((req) => ({
        ...req,
        met: req.regex.test(newPassword),
      }))
    );
  }, [newPassword]);

  const verifyToken = async (email, token) => {
    try {
      setTokenVerificationLoading(true);
      await authApi.resetPasswordConfirm(email, token);
      setTokenVerified(true);
    } catch (error) {
      setTokenError(
        "Your password reset link has expired or is invalid. Please request a new one."
      );
    } finally {
      setTokenVerificationLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (requirements.some((req) => !req.met)) {
      toast.error("Please meet all password requirements.");
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      const searchParams = new URLSearchParams(location.search);
      const email = searchParams.get("email");
      const token = searchParams.get("passwordResetToken");
      await authApi.resetPasswordFinal(email, newPassword, token);
      toast.success(
        "Password reset successfully. Please log in with your new password."
      );
      navigate("/login");
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  if (tokenVerificationLoading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
              Verifying Reset Token
            </Typography>
            <CircularProgress />
          </CardContent>
        </Card>
      </Container>
    );
  }

  if (tokenError) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
              Password Reset Error
            </Typography>
            <Alert severity="error" sx={{ mb: 2 }}>
              {tokenError}
            </Alert>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/reset-password")}
              fullWidth
            >
              Request New Password Reset
            </Button>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Card>
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            {isLoggedIn && (
              <IconButton onClick={handleBack} sx={{ mr: 1 }}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography variant="h5" component="h2">
              Set New Password
            </Typography>
          </Box>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="New Password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirm New Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  fullWidth
                  error={
                    confirmPassword !== "" && newPassword !== confirmPassword
                  }
                  helperText={
                    confirmPassword !== "" && newPassword !== confirmPassword
                      ? "Passwords do not match"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1" gutterBottom>
                  Password Requirements:
                </Typography>
                <List dense>
                  {requirements.map((req, index) => (
                    <ListItem key={index} sx={{ py: 0 }}>
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        {req.met ? (
                          <CheckIcon color="success" />
                        ) : (
                          <CloseIcon color="error" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={req.label} />
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    loading ||
                    requirements.some((req) => !req.met) ||
                    newPassword !== confirmPassword
                  }
                  sx={{ mt: 2 }}
                >
                  {loading ? <CircularProgress size={24} /> : "Reset Password"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default ResetPasswordForm;
