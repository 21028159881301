// src/api/profileApi.js

import axios from 'axios';
import config from '../config/env';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../utils/translations';

const API_URL = config.API_URL;
const WP_BILLING_URL = config.WP_BILLING_URL;

const profileApi = {
    getProfile: async () => {
        try {
            const response = await axios.get(`${API_URL}/profile`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data.results;
        } catch (error) {
            console.error('Error fetching profile:', error);
            throw error;
        }
    },

    getWordPressLoginUrl: async () => {
        try {
            const response = await axios.get(`${API_URL}/wp/wordpress-login-url`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            return `${WP_BILLING_URL}my-account/${response.data.results.loginUrl}`;
        } catch (error) {
            const errorMessage = getErrorMessage('ERROR_LOADING_BILLING_CONTACT_SUPPORT');
            toast.error(errorMessage);
            throw error;
        }
    },
};

export default profileApi;