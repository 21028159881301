import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import SerpOverviewTable from "./SerpOverviewTable";
import ParasiteOpportunitiesTable from "./ParasiteOpportunitiesTable";
import KeywordIdeasTable from "./KeywordIdeasTable";
import ScoreCard from "../ScoreCard";
import generalApi from "../../api/generalApi";
import { formatNumber } from "../../utils/numberFormat";
import styles from "./Dashboard.module.css";


const getParasiteSaturationLabel = (score) => {
  if (score === 0) return "None";
  if (score < 30) return "Low";
  if (score < 60) return "Medium";
  if (score < 80) return "High";
  return "Very High";
};

const getOpportunityScoreLabel = (score) => {
  if (score < 20) return "Low";
  if (score < 40) return "Average";
  if (score < 60) return "Good";
  if (score < 80) return "High";
  return "Very High";
};

const getOpportunityScoreColor = (score) => {
  return "#4ECB71";
};

function ParasitePage() {
  const { id } = useParams();
  const [keywordData, setKeywordData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchKeywordData = async () => {
      setIsLoading(true);
      try {
        const response = await generalApi.keyword.getKeywordForParasite(id);
        const keywordData = response.results;
        setKeywordData(keywordData);
      } catch (error) {
        setError("Failed to fetch keyword data");
        console.error("Error fetching keyword data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchKeywordData();
  }, [id]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!keywordData) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography>No data available</Typography>
      </Box>
    );
  }

  const infoTooltips = {
    parasiteSaturation:
      "Our Parasite Score metric is based on how many parasite domains are ranking for the keyword. Ideally, you want this number somewhere in the middle; A high Parasite Score means less new parasite opportunities, and a low Parasite Score, while good for low competition keywords, can be bad for higher competition keywords since it means that Google is prioritizing other kinds of websites for that term.",
    opportunityScore:
      "Our Opportunity Score metric is based on the availability of new parasite opportunities that are strong for the Primary and Secondary categories, which either aren't already in the SERPs, or are in the SERPs but not satisfying the keyword's search intent.",
  };

  const countParasiteTypes = (parasites) => {
    return parasites.reduce(
      (acc, parasite) => {
        acc[parasite.type]++;
        return acc;
      },
      { free: 0, paid: 0 }
    );
  };

  return (
    <Box className={styles.parasitePage}>
      <Box className={styles.keywordInfo}>
        <Typography variant="h4" className={styles.keywordTitle}>
          Keyword: {keywordData.kw}
        </Typography>
        <Typography variant="body1" className={styles.keywordVolume}>
          <ReactCountryFlag
            countryCode={keywordData.country}
            svg
            className={styles.countryFlag}
          />
          {keywordData.country} Search Volume:{" "}
          {formatNumber(keywordData.ideas[0]?.volume || 0)}
        </Typography>
      </Box>

      <Grid container spacing={3} className={styles.scoreCards}>
        <Grid item xs={12} md={4}>
          <ScoreCard
            title="Parasite Score"
            value={getParasiteSaturationLabel(keywordData.parasiteScore)}
            score={keywordData.parasiteScore}
            color="#9F42FF"
            infoTooltip={infoTooltips.parasiteSaturation}
            subheadings={countParasiteTypes(keywordData.parasiteMatches)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ScoreCard
            title="Opportunity Score"
            value={getOpportunityScoreLabel(keywordData.opportunityScore)}
            score={keywordData.opportunityScore}
            color={getOpportunityScoreColor(keywordData.opportunityScore)}
            infoTooltip={infoTooltips.opportunityScore}
            subheadings={countParasiteTypes(keywordData.parasiteOpportunities)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ScoreCard
            title="Primary Category"
            value={keywordData.categorization.primaryCategory}
            score={keywordData.opportunityScore}
            color={getOpportunityScoreColor(keywordData.opportunityScore)}
            categorization={keywordData.categorization}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={styles.dataTables}>
        <Grid item xs={12} md={6} className={styles.tableContainer}>
          <Card className={styles.tableCard}>
            <CardContent>
              <SerpOverviewTable serps={keywordData.serps} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} className={styles.tableContainer}>
          <Card className={styles.tableCard}>
            <CardContent>
              <ParasiteOpportunitiesTable
                opportunities={keywordData.parasiteOpportunities}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Card className={styles.keywordIdeasCard}>
        <CardContent>
          <KeywordIdeasTable ideas={keywordData.ideas} />
        </CardContent>
      </Card>
    </Box>
  );
}

export default ParasitePage;
