/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, forwardRef } from "react";
import { Autocomplete, TextField, CircularProgress, Box, Tooltip, Typography } from "@mui/material";
import ReactCountryFlag from "react-country-flag";
import generalApi from "../../api/generalApi";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { debounce } from "lodash";

const ListboxComponent = forwardRef(function ListboxComponent(props, ref) {
  const { children, hasMoreCities, ...other } = props;
  return (
    <Box ref={ref} {...other}>
      {children}
      {hasMoreCities && (
        <Typography
          sx={{
            padding: "8px 16px",
            color: "text.secondary",
            fontStyle: "italic",
            textAlign: "center",
          }}
        >
          ...
        </Typography>
      )}
    </Box>
  );
});

const CitySelector = ({ labelId, id, country, value, onChange, disabled, searchEngine, required }) => {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [hasMoreCities, setHasMoreCities] = useState(false);
  const handleError = useErrorHandler();

  const formatCityName = (canonicalName) => {
    const parts = canonicalName.split(',');
    return parts.map(part => part.trim()).join(', ');
  };

  const fetchCities = useCallback(
    debounce(async (searchTerm) => {
      if (country) {
        setLoading(true);
        try {
          const response = await generalApi.city.getCitiesByCountry(country, searchEngine, searchTerm);
          if (response.error) {
            throw new Error(response.message || 'Failed to fetch cities');
          }
          const formattedCities = response.results.map(city => ({
            ...city,
            canonicalName: formatCityName(city.canonicalName)
          }));
          const citiesWithAny = [
            { canonicalName: "Any City", id: "any" },
            ...formattedCities.slice(0, 4)
          ];
          setCities(citiesWithAny);
          setHasMoreCities(formattedCities.length > 4);
        } catch (error) {
          console.error('Error fetching cities:', error);
          handleError(error);
          setCities([{ canonicalName: "Any City", id: "any" }]);
          setHasMoreCities(false);
        } finally {
          setLoading(false);
        }
      } else {
        setCities([{ canonicalName: "Any City", id: "any" }]);
        setHasMoreCities(false);
      }
    }, 400),
    [country, searchEngine, handleError]
  );

  useEffect(() => {
    if (country) {
      fetchCities("a");
      const lastCity = localStorage.getItem(`lastCity_${country}`);
      if (lastCity) {
        const parsedCity = JSON.parse(lastCity);
        onChange(parsedCity);
        setInputValue(parsedCity.canonicalName);
      } else {
        onChange({ canonicalName: "Any City", id: "any" });
        setInputValue("Any City");
      }
    } else {
      setCities([]);
      setHasMoreCities(false);
      onChange(null);
      setInputValue("");
    }
  }, [country, onChange, searchEngine, fetchCities]);

  const handleInputChange = (event, newInputValue, reason) => {
    setInputValue(newInputValue);
    if (reason === "input") {
      if (newInputValue.length >= 2) {
        fetchCities(newInputValue);
      } else if (newInputValue.length === 0) {
        fetchCities("a");
        onChange(null);
      }
    }
  };

  const handleChange = (event, newValue) => {
    if (newValue) {
      onChange(newValue);
      setInputValue(newValue.canonicalName);
      localStorage.setItem(`lastCity_${country}`, JSON.stringify(newValue));
    } else {
      onChange(null);
      setInputValue("");
      localStorage.removeItem(`lastCity_${country}`);
      fetchCities("a");
    }
  };

  return (
    <Autocomplete
      id={id}
      options={cities}
      getOptionLabel={(option) => option.canonicalName || ""}
      renderOption={(props, option) => {
        const { key, ...otherProps } = props;
        return (
          <Box
            component="li"
            key={key}
            {...otherProps}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <ReactCountryFlag
              countryCode={country}
              svg
              style={{ marginRight: "8px", width: "1.5em", height: "1.5em" }}
            />
            <Tooltip title={option.canonicalName} placement="right">
              <span>{option.canonicalName}</span>
            </Tooltip>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="City"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                }}
              >
                <ReactCountryFlag
                  countryCode={country}
                  svg
                  style={{ width: "1.5em", height: "1.5em" }}
                />
              </Box>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      disabled={disabled || !country}
      sx={{
        backgroundColor: "#2C2C2C",
        color: "#fff",
        "& .MuiOutlinedInput-root": {
          paddingLeft: "14px",
          color: "#fff",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#333",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#444",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#666",
        },
        "& .MuiSvgIcon-root": {
          color: "#fff",
        },
        "& .MuiInputLabel-root": {
          color: "#999",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#fff",
        },
      }}
      loading={loading}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      clearOnBlur={false}
      clearOnEscape
      handleHomeEndKeys
      blurOnSelect="touch"
      ListboxProps={{
        hasMoreCities,
        style: { maxHeight: hasMoreCities ? "calc(100% - 30px)" : "100%" },
      }}
      ListboxComponent={ListboxComponent}
      freeSolo
    />
  );
};

export default CitySelector;