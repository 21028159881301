// src/hooks/useErrorHandler.js
import { useCallback, useContext } from "react";
import { AppContext } from "../context/AppContext";
import authApi from "../api/authApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useErrorHandler = () => {
  const { showErrorToast, setShowSubscriptionModal } = useContext(AppContext);
  const navigate = useNavigate();

  const handleError = useCallback(
     (error) => {
      console.log(error)
      if (error.response && error.response.data) {
        const statusCode = error.response.status;
        const message =
          error.response.data?.errors?.[0] ||
          error.response.data?.message ||
          error.response.data;
        if (
          statusCode === 401 
        ) {
           authApi.logout();
          toast.error("Your session has expired. Please log in again.");
          window.dispatchEvent(new CustomEvent("authenticationFailed"));
          setTimeout(() => {
            navigate("/login", { replace: true });
          }, 100);
        } else if (statusCode === 402) {
          setShowSubscriptionModal(true);
        } else if (message) {
          showErrorToast(message);
        } else if (statusCode) {
          showErrorToast(`Error: ${statusCode}`);
        } else {
          //   showErrorToast("An unknown error occurred");
        }
      } else if (error.message) {
        // showErrorToast(error.message);
      } else {
        // showErrorToast('An unknown error occurred');
      }
    },
    [showErrorToast, setShowSubscriptionModal, navigate]
  );
  return handleError;
};
