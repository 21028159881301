import React from 'react';
import { Box, TableContainer, Paper, TableCell } from '@mui/material';
import { keyframes } from '@mui/system';

export const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
`;

export const ParasiteTypeIndicator = ({ type }) => (
  <Box
    sx={{
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: type === 'paid' ? '#9333EA' : '#4ECB71',
      margin: 'auto',
      animation: `${pulse} 1.4s ease-in-out infinite`,
      cursor: 'pointer',
    }}
  />
);

export const StyledTableContainer = ({ children, className }) => (
  <TableContainer
    component={Paper}
    sx={{
      maxHeight: 400,
      width: "100%",
      bgcolor: "#1E1E1E",
      "&::-webkit-scrollbar": {
        width: "12px",
        backgroundColor: "#181818",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "6px",
        backgroundColor: "#9333EA",
      },
      "& .MuiTableRow-root:hover": {
        backgroundColor: "#222223",
      },
    }}
    className={className}
  >
    {children}
  </TableContainer>
);

export const StyledTableContainerForExploreTables = ({ children, className }) => (
  <TableContainer
    component={Paper}
    sx={{
      height: 'calc(100vh - 430px)',
      width: "100%",
      bgcolor: "#1E1E1E",
      "&::-webkit-scrollbar": {
        width: "12px",
        backgroundColor: "#181818",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "6px",
        backgroundColor: "#9333EA",
      },
      "& .MuiTableRow-root:hover": {
        backgroundColor: "#222223",
      },
    }}
    className={className}
  >
    {children}
  </TableContainer>
);

export const StyledTableContainerProjectKeyword = ({ children, className }) => (
  <TableContainer
    component={Paper}
    sx={{
      maxHeight: '100%',
      width: '100%',
      bgcolor: '#1E1E1E',
      '&::-webkit-scrollbar': {
        width: '12px',
        backgroundColor: '#181818',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '6px',
        backgroundColor: '#9333EA',
      },
      '& .MuiTableRow-root:hover': {
        backgroundColor: '#222223',
      },
    }}
    className={className}
  >
    {children}
  </TableContainer>
);

export const TableHeaderCell = ({ children, ...props }) => (
  <TableCell
    sx={{
      bgcolor: '#2C2C2C',
      color: '#fff',
      fontWeight: 'bold',
    }}
    {...props}
  >
    {children}
  </TableCell>
);