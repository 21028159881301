import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

const collapsedDrawerWidth = 64;

function Layout({ children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  
  const [isUltrawide, setIsUltrawide] = useState(false);

  useEffect(() => {
    const checkUltrawide = () => {
      const aspectRatio = window.innerWidth / window.innerHeight;
      setIsUltrawide(aspectRatio > 21/9);
    };

    checkUltrawide();
    window.addEventListener('resize', checkUltrawide);
    return () => window.removeEventListener('resize', checkUltrawide);
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: isMobile ? 2 : 3,
        ml: isMobile ? 0 : `${collapsedDrawerWidth}px`,
        width: isMobile ? '100%' : `calc(100% - ${collapsedDrawerWidth}px)`,
        paddingTop: '40px',
        boxSizing: 'border-box',
        minHeight: '80vh',
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(isMobile ? {} : 
          isTablet ? {
            paddingLeft: '20px',
            paddingRight: '20px',
          } : 
          isUltrawide ? {
            paddingLeft: '10%',
            paddingRight: '10%',
          } : {
            paddingLeft: '5%',
            paddingRight: '5%',
          }
        ),
      }}
    >
      {children}
    </Box>
  );
}

export default Layout;