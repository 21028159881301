// src/api/authApi.js

import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../config/env';

const API_URL = config.API_URL;

const authApi = {
  setAuthToken: (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      localStorage.setItem('token', token);
    } else {
      delete axios.defaults.headers.common['Authorization'];
      localStorage.removeItem('token');
    }
  },

  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/login`, { email, password });
      const jwt = response.data.results.jwt;
      authApi.setAuthToken(jwt);
      toast.success('Login successful');
      return jwt;
    } catch (error) {
      throw error;
    }
  },

  register: async (name, email, password, terms, newsletter) => {
    try {
      const response = await axios.post(`${API_URL}/register`, { name, email, password, terms, newsletter });
      toast.success('Registration successful');
      return response.data;
    } catch (error) {
      throw error;
    }
  },


  logout: () => {
    authApi.setAuthToken(null);
    toast.info('You have been logged out');
  },

  checkAuthStatus: () => {
    const token = localStorage.getItem('token');
    if (token) {
      authApi.setAuthToken(token);
      return true;
    }
    return false;
  },

  confirmEmail: async (email, emailConfirmationToken) => {
    try {
      const response = await axios.post(`${API_URL}/confirmation`, { email, emailConfirmationToken });
      toast.success('Email confirmed successfully');
      return response.data;
    } catch (error) {
      toast.error(error.response?.data?.message || 'Email confirmation failed');
      throw error;
    }
  },

  resetPassword: async (email) => {
    try {
      const response = await axios.post(`${API_URL}/reset`, { email });
      toast.success('Password reset email sent. Please check your inbox.');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  resetPasswordConfirm: async (email, passwordResetToken) => {
    try {
      const response = await axios.post(`${API_URL}/resetconfirm`, { email, passwordResetToken });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  resetPasswordFinal: async (email, password, passwordResetToken) => {
    try {
      const response = await axios.post(`${API_URL}/resetfinal`, { email, password, passwordResetToken });
      toast.success('Password reset successful. You can now log in with your new password.');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

};

export default authApi;