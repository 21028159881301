import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import config from "../config/env";
import authApi from "../api/authApi";

const LimitsContext = createContext();

export const useLimits = () => useContext(LimitsContext);

export const LimitsProvider = ({ children }) => {
  const [limits, setLimits] = useState(null);
  const [hasFetchedLimits, setHasFetchedLimits] = useState(false);

  const fetchLimits = useCallback(
    async (forceRefresh = false) => {
      if (!forceRefresh && (hasFetchedLimits || !authApi.checkAuthStatus()))
        return;

      try {
        const response = await axios.get(`${config.API_URL}/get-limits`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setLimits(response.data.results);
        // console.log("Updated limits:", response.data.results);
        setHasFetchedLimits(true);
      } catch (error) {
        console.error("Error fetching limits:", error);
      }
    },
    [hasFetchedLimits]
  );

  useEffect(() => {
    if (authApi.checkAuthStatus()) {
      fetchLimits();
    }
  }, [fetchLimits]);

  const refreshLimits = useCallback(() => {
    if (authApi.checkAuthStatus()) {
      setHasFetchedLimits(false);
      fetchLimits(true);
    }
  }, [fetchLimits]);

  return (
    <LimitsContext.Provider value={{ limits, fetchLimits: refreshLimits }}>
      {children}
    </LimitsContext.Provider>
  );
};
