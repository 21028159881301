import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import profileApi from '../api/profileApi';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../utils/translations';

function Subscription() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [countdown, setCountdown] = useState(8);

  const fetchSubscriptionUrl = async () => {
    try {
      const url = await profileApi.getWordPressLoginUrl();
      window.location.href = url;
    } catch (error) {
      setIsLoading(false);
      const errorMessage = getErrorMessage('ERROR_LOADING_BILLING_CONTACT_SUPPORT');
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    let timer;
    if (performance.getEntriesByType('navigation')[0].type === 'back_forward') {
      setIsLoading(false);
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            fetchSubscriptionUrl();
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    } else {
      fetchSubscriptionUrl();
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50vh', textAlign: 'center' }}>
        <CircularProgress sx={{ mb: 2 }} />
        <Typography variant="h6">
          Redirecting to subscription page... <br/><br/>If this lasts more than a few seconds, please refresh the page.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50vh', textAlign: 'center' }}>
      <Typography variant="h6" sx={{ mb: 4 }}>
        Redirecting to subscription page in {countdown} seconds...
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", width: '100%', gap: 2 }}>
        <Button
          component={Link}
          to="/dashboard"
          variant="contained"
          size="medium"
          startIcon={<ArrowBackIcon />}
          sx={{
            mb: 2,
            padding: '10px 20px',
            backgroundColor: (theme) => theme.palette.primary.main,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.dark,
            },
          }}
        >
          Back to app
        </Button>
        <Button
          onClick={fetchSubscriptionUrl}
          variant="contained"
          size="medium"
          endIcon={<ArrowForwardIcon />}
          sx={{
            mb: 2,
            padding: '10px 20px',
            backgroundColor: (theme) => theme.palette.primary.main,
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.dark,
            },
          }}
        >
          Manage Subscription
        </Button>
      </Box>
    </Box>
  );
}

export default Subscription;