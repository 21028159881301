import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
  CreditCard as CreditCardIcon,
  Support as SupportIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import profileApi from "../api/profileApi";
import { toast } from "react-toastify";
import { getErrorMessage } from "../utils/translations";
import logo from "../assets/parasite_seo.png";
import Notifications from "./Notifications";

function TopBar({
  isSidenavOpen,
  onMenuClick,
  isMobile,
  onLogout,
  notifications,
  setNotifications,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate("/profile");
    handleMenuClose();
  };

  const handleSubscription = async () => {
    try {
      const url = await profileApi.getWordPressLoginUrl();
      window.open(url, "_blank");
    } catch (error) {
      const errorMessage = getErrorMessage(
        "ERROR_LOADING_BILLING_CONTACT_SUPPORT"
      );
      toast.error(errorMessage);
    }
    handleMenuClose();
  };

  const handleContactSupport = () => {
    window.open('https://parasiteseo.com/contact-us', '_blank');
    handleMenuClose();
  };

  const handleLogout = () => {
    onLogout();
    handleMenuClose();
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#000 !important",
        boxShadow: "none",
        width: "100%",
        transition: "width 0.3s",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={onMenuClick}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
            <Link to="https://app.parasiteseo.com" rel="noopener noreferrer">
              <img
                src={logo}
                alt="Logo"
                style={{ height: "25px", width: "auto", cursor: "pointer" }}
              />
            </Link>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Notifications
            notifications={notifications}
            setNotifications={setNotifications}
          />
          <IconButton
            color="inherit"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuOpen}
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                mt: 1.5,
                "& .MuiMenuItem-root": {
                  py: 1.5,
                  px: 2.5,
                },
              },
            }}
          >
            <MenuItem onClick={handleProfile}>
              <ListItemIcon>
                <PersonIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </MenuItem>
            <MenuItem onClick={handleSubscription}>
              <ListItemIcon>
                <CreditCardIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Subscription & Billing" />
            </MenuItem>
            <MenuItem onClick={handleSubscription}>
              <ListItemIcon>
                <AttachMoneyIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Affiliate Dashboard" />
            </MenuItem>
            <MenuItem onClick={handleContactSupport}>
              <ListItemIcon>
                <SupportIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Contact Support" />
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;