import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import ReactCountryFlag from "react-country-flag";

const countries = [
  { country: "United States", code: "us", language: "English" },
  { country: "United Kingdom", code: "gb", language: "English" },
  { country: "France", code: "fr", language: "French" },
  { country: "Germany", code: "de", language: "German" },
  { country: "Canada", code: "ca", language: "English" },
  { country: "India", code: "in", language: "Hindi" },
  { country: "Australia", code: "au", language: "English" },
  { country: "Indonesia", code: "id", language: "Indonesian" },
  { country: "Netherlands", code: "nl", language: "Dutch" },
  { country: "Brazil", code: "br", language: "Portuguese" },
  { country: "Italy", code: "it", language: "Italian" },
  { country: "Austria", code: "at", language: "German" },
  { country: "Spain", code: "es", language: "Spanish" },
  { country: "New Zealand", code: "nz", language: "English" },
  { country: "Russia", code: "ru", language: "Russian" },
  { country: "Turkey", code: "tr", language: "Turkish" },
  { country: "Denmark", code: "dk", language: "Danish" },
  { country: "Norway", code: "no", language: "Norwegian" },
  { country: "Sweden", code: "se", language: "Swedish" },
  { country: "Switzerland", code: "ch", language: "German, French, Italian, Romansh" }
];

function CountrySelector({ labelId, id, value, onChange, required }) {
  return (
    <FormControl fullWidth required={required}>
      <InputLabel id={labelId}>Country</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label="Country"
        sx={{
          backgroundColor: '#2C2C2C',
          color: '#fff',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#333',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#444',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#666',
          },
          '& .MuiSvgIcon-root': {
            color: '#fff',
          },
        }}
      >
        {countries.map((country) => (
          <MenuItem key={country.code} value={country.code}>
            <ReactCountryFlag
              countryCode={country.code}
              svg
              style={{
                width: '1.5em',
                height: '1.5em',
                marginRight: '10px'
              }}
            />
            {country.country}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CountrySelector;