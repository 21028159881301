import React, { useState, useEffect, useCallback } from 'react';
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  Grid,
  IconButton,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Search as SearchIcon,
  Save as SaveIcon,
  Refresh as RefreshIcon,
  Share as ShareIcon,
  Close as CloseIcon,
  SmartToy as RobotIcon,
  Grading as NotepadIcon,
  GetApp as ExportIcon,
} from '@mui/icons-material';
import ReactCountryFlag from "react-country-flag";
import SerpOverviewTable from './SerpOverviewTable';
import ParasiteOpportunitiesTable from './ParasiteOpportunitiesTable';
import KeywordIdeasTable from './KeywordIdeasTable';
import CountrySelector from './CountrySelector';
import CitySelector from './CitySelector';
import SearchEngineSelector from './SearchEngineSelector';
import ShareModal from "./ShareModal";
import ScoreCard from '../ScoreCard';
import { toast } from 'react-toastify';
import { formatNumber } from '../../utils/numberFormat';
import styles from './Dashboard.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useErrorHandler } from '../../hooks/useErrorHandler';
import ProjectSelector from '../ProjectSelector';
import AddProjectModal from '../Project/AddProjectModal';
import AiOverviewModal from './AiOverviewModal';
import generalApi, { setGlobalRefreshLimits } from '../../api/generalApi';
import { useLimits } from '../../context/LimitsContext';



const useTypingEffect = (text, delay = 50) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let i = 0;
    const timer = setInterval(() => {
      if (i < text.length) {
        setDisplayText(text.slice(0, i + 1));
        i++;
      } else {
        clearInterval(timer);
      }
    }, delay);

    return () => clearInterval(timer);
  }, [text, delay]);

  return displayText;
};

const getOpportunityScoreLabel = (score) => {
  if (score < 20) return 'Low';
  if (score < 40) return 'Average';
  if (score < 60) return 'Good';
  if (score < 80) return 'High';
  return 'Very High';
};

const getOpportunityScoreColor = (score) => {
  if (score >= 70) return '#4ECB71';
  if (score >= 50) return '#F8D66D';
  if (score >= 20) return '#FFB54C';
  return '#FF6961';
};

const getParasiteSaturationColor = (score) => {
  if (score < 40) return '#4ECB71';
  if (score < 60) return '#F8D66D';
  if (score < 80) return '#FFB54C';
  return '#FF6961';
};

const getParasiteSaturationLabel = (score) => {
  if (score < 65) return 'Low';
  if (score < 80) return 'Medium';
  if (score < 90) return 'High';
  return 'Very High';
};

const countAllParasites = (serps) => {
  return {
    free: serps.filter(serp => serp.isParasite && serp.parasiteType === "free").length,
    paid: serps.filter(serp => serp.isParasite && serp.parasiteType === "paid").length
  };
};

function Dashboard() {
  const { keywordId } = useParams();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [displayedKeyword, setDisplayedKeyword] = useState("");
  const [country, setCountry] = useState(() => localStorage.getItem("lastCountry") || "us");
  const [city, setCity] = useState(() => {
    const savedCity = localStorage.getItem(`lastCity_${country}`);
    return savedCity ? JSON.parse(savedCity) : null;
  });
  const [searchEngine, setSearchEngine] = useState(() => localStorage.getItem("lastSearchEngine")?.toLowerCase() || "google");
  // eslint-disable-next-line no-unused-vars
  const [lastUpdated, setLastUpdated] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [language, setLanguage] = useState("en");
  // eslint-disable-next-line no-unused-vars
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [isInitialView, setIsInitialView] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isKeywordSaved, setIsKeywordSaved] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [newKeyword, setNewKeyword] = useState("");
  const [isAddKeywordModalOpen, setIsAddKeywordModalOpen] = useState(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [createdKeywordId, setCreatedKeywordId] = useState(null);
  const [error, setError] = useState(null);
  const handleError = useErrorHandler();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [isAiOverviewModalOpen, setIsAiOverviewModalOpen] = useState(false);
  const [aiOverviewData, setAiOverviewData] = useState(null);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [parasitesInTop10, setParasitesInTop10] = useState({ free: 0, paid: 0 });
  const { fetchLimits } = useLimits();



  const typedTitle = useTypingEffect("Parasite SEO Made Easy");
  const typedSubtitle = useTypingEffect(
    "The easiest, most consistent way to get high organic traffic fast",
    25
  );

  useEffect(() => {
    if (keywordId) {
      fetchKeywordData(keywordId);
      setIsKeywordSaved(true);
    } else {
      setIsKeywordSaved(false);
      setError(null);
    }
  }, [keywordId]);

  useEffect(() => {
    if (dashboardData && dashboardData.serps) {
      const counts = countAllParasites(dashboardData.serps);
      setParasitesInTop10(counts);
    }
  }, [dashboardData]);


  const fetchKeywordData = async (id) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await generalApi.keyword.getKeyword(id);
      if (response.statusCode === 200) {
        const keywordData = response.results;
        console.log("Keyword", keywordData);
        setKeyword(keywordData.kw);
        setDisplayedKeyword(keywordData.kw);
        setCountry(keywordData.country || "us");
        setCity(keywordData.location ? { canonicalName: keywordData.location, id: keywordData.location } : null);
        setSearchEngine(keywordData.searchEngine?.toLowerCase() || "google");
        setDashboardData(keywordData);
        setIsInitialView(false);
      } else {
        throw new Error(response.message || "Failed to fetch keyword data");
      }
    } catch (error) {
      setError(error.message || "An unexpected error occurred");
      toast.error("Failed to fetch keyword data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCountryChange = (newCountry) => {
    setCountry(newCountry);
    setCity(null);
    localStorage.setItem("lastCountry", newCountry);
    localStorage.removeItem(`lastCity_${newCountry}`);
  };

  const handleCityChange = useCallback((newCity) => {
    setCity(newCity);
    if (newCity) {
      localStorage.setItem(`lastCity_${country}`, JSON.stringify(newCity));
    } else {
      localStorage.removeItem(`lastCity_${country}`);
    }
  }, [country]);

  const handleSearchEngineChange = (newSearchEngine) => {
    setSearchEngine(newSearchEngine);
    localStorage.setItem("lastSearchEngine", newSearchEngine);
  };

  useEffect(() => {
    setGlobalRefreshLimits(fetchLimits);
  }, [fetchLimits]);

  const handleSearch = async () => {
    if (!keyword.trim()) {
      toast.error("Please enter a keyword before searching.");
      return;
    }
    if (!country) {
      toast.error("Please select a country before searching.");
      return;
    }
    setIsLoading(true);
    try {
      const data = await generalApi.getKeywordAnalysis(
        keyword,
        country,
        city?.canonicalName || "Any City",
        language,
        searchEngine
      );
      setDashboardData(data.results);
      setIsInitialView(false);
      setDisplayedKeyword(keyword);
      navigate("/dashboard", { replace: true });
      setCreatedKeywordId(null);
      setIsKeywordSaved(false);
      fetchLimits();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeywordClick = (clickedKeyword) => {
    setNewKeyword(clickedKeyword);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirm = () => {
    setKeyword(newKeyword);
    setDisplayedKeyword(newKeyword);
    handleSearch();
    setOpenDialog(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      const data = await generalApi.getKeywordAnalysis(
        keyword,
        country,
        city?.canonicalName || "Any City",
        language,
        searchEngine,
        keywordId
      );
      setDashboardData({ ...data.results, aiOverview: null });
      const keywordIdToUse = createdKeywordId || keywordId;
      if (keywordIdToUse) {
        await generalApi.keyword.updateKeywordRefreshDate(keywordIdToUse);
        setLastUpdated(new Date().toLocaleString());
      }
      toast.success("Keyword data refreshed successfully");
      // The generalApi method should handle limit updates internally
    } catch (error) {
      handleError(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleShare = () => {
    setIsShareModalOpen(true);
  };

  const getShareUrl = () => {
    const baseUrl = window.location.origin;
    const keywordIdToUse = createdKeywordId || keywordId;
    return `${baseUrl}/parasite/${keywordIdToUse}`;
  };

  const fetchProjects = useCallback(async () => {
    setIsLoadingProjects(true);
    try {
      const response = await generalApi.project.getProjectsLists();
      if (response && response.results) {
        setProjects(response.results);
        const matchingProjects = response.results.filter(project => project.country === country);
        setFilteredProjects(matchingProjects);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Failed to fetch projects:", error);
      handleError(error);
      toast.error("Unable to load projects. Please try again.");
    } finally {
      setIsLoadingProjects(false);
    }
  }, [handleError, country]);

  const handleSaveKeyword = () => {
    setIsAddKeywordModalOpen(true);
    fetchProjects();
  };

  const handleProjectSelect = (project) => {
    if (project && project._id === 'add_new') {
      setIsAddProjectModalOpen(true);
    } else if (project && project.country !== country) {
      toast.error("Selected project's country does not match the keyword's country. Please select a matching project or create a new one.");
    } else {
      setSelectedProject(project);
    }
  };

  const handleAddProject = async (projectData) => {
    if (projectData.country !== country) {
      toast.error("Project country must match the keyword's country.");
      return;
    }
    try {
      const response = await generalApi.project.createProject(projectData);
      const newProject = response.results;
      setProjects([...projects, newProject]);
      setFilteredProjects([...filteredProjects, newProject]);
      setSelectedProject(newProject);
      setIsAddProjectModalOpen(false);
      toast.success("New project created successfully");
    } catch (error) {
      handleError(error);
    }
  };

  const handleSaveKeywordToProject = async () => {
    if (!selectedProject) {
      toast.error("Please select a project");
      return;
    }

    try {
      const keywordRequestBody = {
        kw: dashboardData.categorization.kw,
        projectId: selectedProject._id,
        categories: dashboardData.categorization.categories,
        subcategories: dashboardData.categorization.subcategories,
        parasiteMatches: dashboardData.parasiteMatches,
        parasiteOpportunities: dashboardData.parasiteOpportunities,
        parasiteScore: dashboardData.parasiteScore,
        opportunityScore: dashboardData.opportunityScore,
        opportunityScoreInitial: dashboardData.opportunityScoreInitial,
        serps: dashboardData.serps,
        ideas: dashboardData.ideas,
        country: dashboardData.country,
        location: dashboardData.location,
      };
      const response = await generalApi.keyword.createKeyword(keywordRequestBody);
      setCreatedKeywordId(response.results._id);
      setIsKeywordSaved(true);
      toast.success("Keyword added to project successfully");
      setIsAddKeywordModalOpen(false);
    } catch (error) {
      handleError(error);
    }
  };

  const handleAiOverview = async () => {
    setIsLoading(true);
    try {
      const keywordIdToUse = keywordId || createdKeywordId;
      const response = await generalApi.aiOverview.getAiOverview(keywordIdToUse);
      setAiOverviewData(response.results);
      setDashboardData(prevData => ({
        ...prevData,
        aiOverview: response.results
      }));
      setIsAiOverviewModalOpen(true);
      // The generalApi method should handle limit updates internally
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  // eslint-disable-next-line no-unused-vars
  const handleExport = (fileType) => {
    const data = prepareExportData();
    const delimiter = fileType === 'csv' ? ',' : '\t';
    const content = createFileContent(data, delimiter);
    const fileName = generateFileName(displayedKeyword || keyword, fileType);
    triggerDownload(content, fileName);
    handleExportClose();
  };

  const prepareExportData = () => {
    if (!dashboardData) return null;

    const freeOpportunities = dashboardData.parasiteOpportunities.filter(opp => opp.type === 'free');
    const paidOpportunities = dashboardData.parasiteOpportunities.filter(opp => opp.type === 'paid');

    const getPrimaryCategoryScore = (opportunity) =>
      Math.max(...opportunity.categories.map(cat => cat.score));

    const data = [
      {
        Keyword: dashboardData.categorization.kw,
        PrimaryCategory: dashboardData.categorization.primaryCategory,
        ParasiteOpportunityScore: dashboardData.opportunityScore,
        SERPParasiteScore: dashboardData.parasiteScore,
      }
    ];

    // Add free opportunities
    freeOpportunities.forEach(opp => {
      data.push({
        "Free Parasite Domain": opp.domain,
        "Free Parasite Score": getPrimaryCategoryScore(opp),
      });
    });

    // Add paid opportunities
    paidOpportunities.forEach(opp => {
      data.push({
        "Paid Parasite Domain": opp.domain,
        "Paid Parasite Score": getPrimaryCategoryScore(opp),
      });
    });

    // Add AI overview data if available
    if (aiOverviewData) {
      data.push({ "Keyword Description": aiOverviewData.keywordDescription });
      data.push({ "SERP Description": aiOverviewData.serpDescription });
      data.push({ "Preferred Parasite Type": aiOverviewData.preferFreeOrPaidParasitesForThisKeyword });

      // Add topics
      aiOverviewData.topicsToCoverInOutlineAndWhatToTalkInEach.forEach((topic, index) => {
        data.push({
          [`Topic ${index + 1}`]: topic.topic,
          [`Topic ${index + 1} Description`]: topic.description,
        });
      });

      // Add free parasite examples
      aiOverviewData.freeParasitesWithTitlesAndDescriptions.forEach((parasite, index) => {
        data.push({
          [`Free Parasite Example ${index + 1}`]: parasite.parasiteDomain,
          [`Free Parasite Example ${index + 1} Title`]: parasite.exampleArticleTitle,
          [`Free Parasite Example ${index + 1} Description`]: parasite.exampleArticleDescription,
        });
      });

      // Add paid parasite examples
      aiOverviewData.paidParasitesWithTitlesAndDescriptions.forEach((parasite, index) => {
        data.push({
          [`Paid Parasite Example ${index + 1}`]: parasite.parasiteDomain,
          [`Paid Parasite Example ${index + 1} Title`]: parasite.exampleArticleTitle,
          [`Paid Parasite Example ${index + 1} Description`]: parasite.exampleArticleDescription,
        });
      });
    }

    return data;
  };
  const createFileContent = (data, delimiter) => {
    const allKeys = data.reduce((keys, row) => {
      Object.keys(row).forEach(key => {
        if (!keys.includes(key)) {
          keys.push(key);
        }
      });
      return keys;
    }, []);

    const rows = [allKeys.join(delimiter)];

    data.forEach(row => {
      const rowData = allKeys.map(key => {
        const value = row[key] || '';
        return `"${value.toString().replace(/"/g, '""')}"`;
      });
      rows.push(rowData.join(delimiter));
    });

    return rows.join('\n');
  };

  const generateFileName = (keyword, fileType) => {
    const timestampDate = new Date().toISOString().slice(0, 10);
    return `${keyword}_Parasite_SEO_${timestampDate}.${fileType}`;
  };

  const triggerDownload = (content, fileName) => {
    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const AiText = styled('span')(({ disabled }) => ({
    position: 'absolute',
    bottom: 3,
    right: 3,
    fontSize: '13px',
    fontWeight: 'bold',
    color: disabled ? '#B1F938' : '#B1F938',
    pointerEvents: 'none', // This ensures the text doesn't interfere with button clicks
    // text outline black
    WebkitTextStroke: '0.6px black',
  }));

  const AiOverviewButton = () => {
    const hasAiOverview = dashboardData?.aiOverview;

    const tooltipTitle = isKeywordSaved
      ? (hasAiOverview ? "Load AI Overview" : "Generate AI Overview (experimental while in Beta until Sept 25th)")
      : "Save keyword before generating AI Overview";

    return (
      <Tooltip title={tooltipTitle}>
        <span style={{ position: 'relative', display: 'inline-block' }}>
          <IconButton
            onClick={handleAiOverview}
            className={styles.actionButton}
            disabled={!isKeywordSaved}
          >
            {hasAiOverview ? (
              <NotepadIcon sx={{ color: isKeywordSaved ? 'white' : '#7F7F7F' }} />
            ) : (
              <RobotIcon sx={{ color: isKeywordSaved ? '#9C42F8' : '#7F7F7F' }} />
            )}
          </IconButton>
          <Tooltip title={tooltipTitle}>
            <AiText disabled={!isKeywordSaved}>AI</AiText>
          </Tooltip>
        </span>
      </Tooltip>
    );
  };

  const LoadingOverlay = () => (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      <CircularProgress />
    </Box>
  );

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 164px)",
        }}
      >
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
        <Typography variant="body1">
          <a href="/dashboard" style={{ color: "#6DB9FF" }}>
            Return to Dashboard
          </a>
        </Typography>
      </Box>
    );
  }

  return (
    <Box className={styles.dashboard}>
      {!isLoading && isInitialView ? (
        <Box className={styles.initialView}>
          <Typography variant="h3" className={styles.title}>
            {typedTitle}
          </Typography>
          <Typography variant="h6" className={styles.subtitle}>
            {typedSubtitle}
          </Typography>
          <Card className={styles.searchCard}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={3.5}>
                  <TextField
                    id="keyword-input"
                    label="Keyword"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className={styles.searchIcon} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      backgroundColor: "#2C2C2C",
                      "& .MuiOutlinedInput-root": {
                        color: "#fff",
                        "& fieldset": {
                          borderColor: "#333",
                        },
                        "&:hover fieldset": {
                          borderColor: "#444",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#666",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#999",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#fff",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={1.5}>
                  <SearchEngineSelector
                    labelId="search-engine-select-label"
                    id="search-engine-select"
                    value={searchEngine}
                    onChange={handleSearchEngineChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={2.6}>
                  <CountrySelector
                    labelId="country-select-label"
                    id="country-select"
                    value={country}
                    onChange={handleCountryChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={2.6}>
                  <CitySelector
                    labelId="city-select-label"
                    id="city-select"
                    country={country}
                    value={city}
                    onChange={handleCityChange}
                    disabled={!country}
                    searchEngine={searchEngine}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={1.8}>
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                    fullWidth
                    className={styles.searchButton}
                  >
                    Analyze
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Box className={styles.resultView}>
          <Card className={styles.searchBar}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={3.1}>
                  <TextField
                    id="keyword-input"
                    label="Keyword"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon className={styles.searchIcon} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      backgroundColor: "#2C2C2C",
                      "& .MuiOutlinedInput-root": {
                        color: "#fff",
                        "& fieldset": {
                          borderColor: "#333",
                        },
                        "&:hover fieldset": {
                          borderColor: "#444",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#666",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#999",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#fff",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={1.7}>
                  <SearchEngineSelector
                    labelId="search-engine-select-label"
                    id="search-engine-select"
                    value={searchEngine}
                    onChange={handleSearchEngineChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={2.8}>
                  <CountrySelector
                    labelId="country-select-label"
                    id="country-select"
                    value={country}
                    onChange={handleCountryChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={2.6}>
                  <CitySelector
                    labelId="city-select-label"
                    id="city-select"
                    country={country}
                    value={city}
                    onChange={handleCityChange}
                    disabled={!country}
                    searchEngine={searchEngine}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={1.8}>
                  <Button
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={handleSearch}
                    fullWidth
                    className={styles.searchButton}
                  >
                    Analyze
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {dashboardData && (
            <>
              <Box className={styles.keywordInfo}>
                <Box className={styles.keywordInfoLeft}>
                  <Typography variant="h4" className={styles.keywordTitle}>
                    Keyword: {displayedKeyword || keyword}
                  </Typography>
                  <Typography variant="body1" className={styles.keywordVolume}>
                    <ReactCountryFlag
                      countryCode={country}
                      svg
                      className={styles.countryFlag}
                    />
                    {country.toUpperCase()} Search Volume:{" "}
                    {formatNumber(dashboardData.ideas[0]?.volume || 0)}
                  </Typography>
                </Box>
                <Box className={styles.actionButtons}>
                  <AiOverviewButton />
                  {isKeywordSaved && (
                    <Tooltip title="Re-fetch fresh data">
                      <IconButton
                        onClick={handleRefresh}
                        className={styles.actionButton}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!isKeywordSaved && (
                    <Tooltip title="Save keyword">
                      <IconButton
                        onClick={handleSaveKeyword}
                        className={styles.actionButton}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isKeywordSaved && (
                    <Tooltip title="Share this parasite analysis">
                      <IconButton
                        onClick={handleShare}
                        className={styles.actionButton}
                      >
                        <ShareIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Export data">
                    <IconButton
                      onClick={handleExportClick}
                      className={styles.actionButton}
                    >
                      <ExportIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={exportAnchorEl}
                    open={Boolean(exportAnchorEl)}
                    onClose={handleExportClose}
                    sx={{ marginRight: "20px" }}
                  >
                    <Tooltip title="Coming Soon (Sept 25th)" placement="right">
                      <span>
                        <MenuItem
                          disabled
                          style={{ opacity: 0.5, cursor: "not-allowed" }}
                        >
                          Export as CSV
                        </MenuItem>
                      </span>
                    </Tooltip>
                    <Tooltip title="Coming Soon (Sept 25th)" placement="right">
                      <span>
                        <MenuItem
                          disabled
                          style={{ opacity: 0.5, cursor: "not-allowed" }}
                        >
                          Export as TSV
                        </MenuItem>
                      </span>
                    </Tooltip>
                  </Menu>
                </Box>
              </Box>

              <Grid container spacing={3} className={styles.scoreCards}>
              <Grid item xs={12} md={4}>
  <ScoreCard
    title="SERP Parasite Score"
    value={getParasiteSaturationLabel(
      dashboardData.parasiteScore
    )}
    score={dashboardData.parasiteScore}
    color={getParasiteSaturationColor(
      dashboardData.parasiteScore
    )}
    infoTooltip="SERP Parasite Score is our proprietary metric that evaluates how many parasites there are in the SERPs already. This is to be used for reference; for example, a low SERP Parasite Score is good for low competition keywords, but can be bad for high competition keywords since it could mean that Google is prioritizing different types of websites for that keyword."
    subheadings={{
      free: parasitesInTop10.free,
      paid: parasitesInTop10.paid,
    }}
  />
</Grid>
  <Grid item xs={12} md={4}>
    <ScoreCard
      title="Parasite Opportunity Score"
      value={getOpportunityScoreLabel(
        dashboardData.opportunityScore
      )}
      score={dashboardData.opportunityScore}
      color={getOpportunityScoreColor(
        dashboardData.opportunityScore
      )}
      infoTooltip={
        <span>
          Parasite Opportunity Score is our proprietary metric
          calculated by our AI models.
          <br />
          It evaluates how much opportunity there is to rank this
          keyword in the current SERPs based on the available
          parasite opportunities.
        </span>
      }
      subheadings={{
        free: dashboardData.parasiteOpportunities.filter(
          (opp) => opp.type === "free"
        ).length,
        paid: dashboardData.parasiteOpportunities.filter(
          (opp) => opp.type === "paid"
        ).length,
      }}
    />
  </Grid>
  <Grid item xs={12} md={4}>
    <ScoreCard
      title="Primary Category"
      value={dashboardData.categorization.primaryCategory}
      score={dashboardData.opportunityScore}
      color={getOpportunityScoreColor(
        dashboardData.opportunityScore
      )}
      onEdit={() => {
        /* Implement edit functionality */
      }}
      categorization={dashboardData.categorization}
      infoTooltip="The categorization of this keyword and its SERPs, done by our AI with 99% accuracy (tested)."
    />
  </Grid>
  </Grid>

              <Grid container spacing={3} className={styles.dataTables}>
                <Grid item xs={12} md={6} className={styles.tableContainer}>
                  <Card className={styles.tableCard}>
                    <CardContent>
                      <SerpOverviewTable serps={dashboardData.serps} />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} className={styles.tableContainer}>
                  <Card className={styles.tableCard}>
                    <CardContent>
                      <ParasiteOpportunitiesTable
                        opportunities={dashboardData.parasiteOpportunities}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Card className={styles.keywordIdeasCard}>
                <CardContent>
                  <KeywordIdeasTable
                    ideas={dashboardData.ideas}
                    onKeywordClick={handleKeywordClick}
                  />
                </CardContent>
              </Card>
            </>
          )}
        </Box>
      )}

      {isLoading && <LoadingOverlay />}

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Confirm New Analysis</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to analyze the keyword "{newKeyword}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0 20px 20px" }}>
          <Button
            onClick={handleDialogClose}
            color="secondary"
            sx={{ color: "#fff", mr: 1, bgcolor: "#595959" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDialogConfirm}
            variant="contained"
            className={styles.addButton}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isAddKeywordModalOpen}
        onClose={() => setIsAddKeywordModalOpen(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            background: "#292929",
            color: "#fff",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          Add Keyword to Project
          <IconButton
            onClick={() => setIsAddKeywordModalOpen(false)}
            sx={{ color: "#fff" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ background: "#292929", p: 2 }}>
          {isLoadingProjects ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="200px"
            >
              <CircularProgress />
            </Box>
          ) : filteredProjects.length === 0 ? (
            <Box sx={{ textAlign: "center", my: 2 }}>
              <Typography sx={{ marginBottom: "16px", color: "#fff" }}>
                No matching projects found for this country.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsAddProjectModalOpen(true)}
                className={styles.addButton}
              >
                Create New Project
              </Button>
            </Box>
          ) : (
            <Box sx={{ mt: 2 }}>
              <ProjectSelector
                value={selectedProject}
                onChange={handleProjectSelect}
                onAddProject={() => setIsAddProjectModalOpen(true)}
                className={styles.projectSelector}
                projects={filteredProjects}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: 2,
            background: "#292929",
          }}
        >
          <Box>{/* You can add additional content here if needed */}</Box>
          <Box>
            <Button
              onClick={() => setIsAddKeywordModalOpen(false)}
              color="secondary"
              sx={{ color: "#fff", mr: 1, bgcolor: "#595959" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveKeywordToProject}
              variant="contained"
              color="primary"
              disabled={!selectedProject && filteredProjects.length > 0}
            >
              Save Keyword
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <AddProjectModal
        open={isAddProjectModalOpen}
        onClose={() => setIsAddProjectModalOpen(false)}
        onSubmit={handleAddProject}
        initialCountry={country}
        disableCountrySelection={true}
      />

      <ShareModal
        open={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        shareUrl={getShareUrl()}
      />

      <AiOverviewModal
        open={isAiOverviewModalOpen}
        onClose={() => setIsAiOverviewModalOpen(false)}
        data={aiOverviewData}
        parasiteOpportunities={dashboardData?.parasiteOpportunities || []}
        dashboardKeyword={displayedKeyword || keyword}
        categories={dashboardData?.categorization || {}}
      />
    </Box>
  );
}

export default Dashboard;