// src/components/SubscriptionLimitModal.js

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography
} from '@mui/material';

function SubscriptionLimitModal({ open, onClose }) {
    const handleUpgrade = () => {
        // Implement upgrade logic here
        console.log('Upgrade clicked');
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    background: '#292929',
                    color: '#fff',
                },
            }}
        >
            <DialogTitle>Subscription Limit Reached</DialogTitle>
            <DialogContent>
                <Typography>
                    Sorry, you've reached your subscription limit. <br/> Please upgrade to increase your limits.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" sx={{ color: '#fff', mr: 1, bgcolor:'#595959' }}>
                    Cancel
                </Button>
                <Button onClick={handleUpgrade} variant="contained" style={{ backgroundColor: '#9333EA' }} component={RouterLink} to="/subscription" target='_blank'>
                    Upgrade
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SubscriptionLimitModal;