import React, { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastify-custom.css";
// import App.css
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import VideoScreen from "./components/VideoScreen";


import theme from "./theme";

import { AppProvider } from "./context/AppContext";
import { LimitsProvider, useLimits } from "./context/LimitsContext";

import authApi from "./api/authApi";
import {
  setGlobalShowSubscriptionModal,
  setGlobalRefreshLimits,
} from "./api/generalApi";
import TopBar from "./components/TopBar";
import Sidenav from "./components/Sidenav";
import BulkChecks from "./components/BulkCheck/BulkCheck";
import Dashboard from "./components/Dashboard/Dashboard";
import Search from "./components/Search";
import Login from "./components/Login";
import Register from "./components/Register";
import PasswordReset from "./components/PasswordReset";
import ResetPasswordForm from "./components/ResetPasswordForm";
import AuthLayout from "./components/AuthLayout";
import Layout from "./components/Layout";
import Profile from "./components/Profile";
import ProjectScreen from "./components/Project/ProjectScreen";
import Subscription from "./components/Subscription";
import CategoryDashboard from "./components/Category/CategoryDashboard";
import SubscriptionLimitModal from "./components/SubscriptionLimitModal";
import ParasitePage from "./components/Dashboard/ParasitePage";
import { io } from "socket.io-client";

import config from "./config/env";

const SOCKET_BE_URL = config.SOCKET_BE_URL;

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSidenavOpen, setIsSidenavOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { fetchLimits } = useLimits();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState({
    jobNotifications: [],
    marketingNotifications: [],
  });

  const handleShowSubscriptionModal = useCallback(() => {
    setShowSubscriptionModal(true);
  }, []);

  useEffect(() => {
    setGlobalShowSubscriptionModal(handleShowSubscriptionModal);
    setGlobalRefreshLimits(fetchLimits);
  }, [handleShowSubscriptionModal, fetchLimits]);


    useEffect(() => {
      const newSocket = io(SOCKET_BE_URL, {
        auth: {
          token: localStorage.getItem("token"), // Assuming you store the JWT token in localStorage
        },
      });
      

      newSocket.on("connect", () => {
        console.log("Connected to socket server");
      });

      newSocket.on("disconnect", () => {
        console.log("Disconnected from socket server");
      });

      newSocket.on("jobNotifications", (notification) => {
         setNotifications((prevNotifications) => ({
           ...prevNotifications,
           jobNotifications: [
             notification.results,
             ...prevNotifications.jobNotifications,
           ],
         })); 
      });
      
      setSocket(newSocket);

      return () => {
        newSocket.disconnect();
      };
    }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const isAuth = authApi.checkAuthStatus();
      setIsAuthenticated(isAuth);
      setIsLoading(false);
    };
    checkAuth();

    const handleAuthFailure = () => {
      setIsAuthenticated(false);
    };
    window.addEventListener("authenticationFailed", handleAuthFailure);

    return () => {
      window.removeEventListener("authenticationFailed", handleAuthFailure);
    };
  }, []);

  // New useEffect for updating the tab title
  useEffect(() => {
    const path = location.pathname;
    let title = "Parasite SEO";

    switch (path) {
      case "/":
      case "/dashboard":
        title = "Dashboard | Parasite SEO";
        break;
      case "/projects":
        title = "Projects | Parasite SEO";
        break;
      case "/search":
        title = "Search | Parasite SEO";
        break;
      case "/profile":
        title = "Profile | Parasite SEO";
        break;
      case "/subscription":
        title = "Subscription | Parasite SEO";
        break;
      case "/trending":
        title = "Trending | Parasite SEO";
        break;
      case "/login":
        title = "Login | Parasite SEO";
        break;
      case "/register":
        title = "Register | Parasite SEO";
        break;
      case "/reset-password":
        title = "Reset Password | Parasite SEO";
        break;
      case "/bulk-checks":
        title = "Bulk Checks | Parasite SEO";
        break;
      default:
        if (path.startsWith("/dashboard/")) {
          title = "Keyword Analysis | Parasite SEO";
        }
    }

    document.title = title;
  }, [location]);

  const handleLogin = (token) => {
    authApi.setAuthToken(token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    authApi.logout();
    setIsAuthenticated(false);
  };

  const handleSidenavToggle = () => {
    setIsSidenavOpen(!isSidenavOpen);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const showTopBar =
    isAuthenticated &&
    !["/reset", "/reset-password"].includes(location.pathname);

    const ParasitePageWrapper = ({ isAuthenticated }) =>
      isAuthenticated ? (
        <Layout isMobile={isMobile}>
          <ParasitePage />
        </Layout>
      ) : (
        <AuthLayout isAuthenticated={false}>
          <ParasitePage />
        </AuthLayout>
      );


  return (
    <>
      <SubscriptionLimitModal
        open={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
      />
      <div className="App">
        {showTopBar && (
          <>
            <TopBar
              isSidenavOpen={isSidenavOpen}
              onMenuClick={handleSidenavToggle}
              onLogout={handleLogout}
              isMobile={isMobile}
              notifications={notifications}
              setNotifications={setNotifications}
            />
            <Sidenav
              open={isSidenavOpen}
              onClose={() => setIsSidenavOpen(false)}
              isMobile={isMobile}
            />
          </>
        )}
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <AuthLayout isAuthenticated={isAuthenticated}>
                  <Login onLogin={handleLogin} />
                </AuthLayout>
              )
            }
          />
          <Route
            path="/register"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <AuthLayout isAuthenticated={isAuthenticated}>
                  <Register />
                </AuthLayout>
              )
            }
          />
          <Route
            path="/reset-password"
            element={
              <AuthLayout isAuthenticated={isAuthenticated}>
                <PasswordReset />
              </AuthLayout>
            }
          />
          <Route
            path="/reset"
            element={
              <AuthLayout isAuthenticated={isAuthenticated}>
                <ResetPasswordForm />
              </AuthLayout>
            }
          />
          {/* Add the new ParasitePage route */}
          <Route
            path="/parasite/:id"
            element={<ParasitePageWrapper isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/*"
            element={
              isAuthenticated ? (
                <Layout isMobile={isMobile}>
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate to="/dashboard" replace />}
                    />
                    <Route path="/bulk-checks" element={<BulkChecks />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                      path="/dashboard/:keywordId"
                      element={<Dashboard />}
                    />
                    <Route path="/projects" element={<ProjectScreen />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/subscription" element={<Subscription />} />
                    <Route path="/trending" element={<CategoryDashboard />} />
                    <Route path="/training" element={<VideoScreen />} />


                    <Route
                      path="*"
                      element={<Navigate to="/dashboard" replace />}
                    />
                  </Routes>
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppProvider>
        <LimitsProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app-wrapper">
              <div className="background-container"></div>
              <AppContent />
            </div>
          </ThemeProvider>
        </LimitsProvider>
      </AppProvider>
    </Router>
  );
}

export default App;