// src/components/Dashboard.js
import React from 'react';
import { Box, Typography } from '@mui/material';

function Search() {
  return (
    <Box sx={{ 
      flexGrow: 1, 
      p: 3, 
      // mt: '0', // To account for the TopBar height
    }}>
      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>
      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>
      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>

      <Typography paragraph>
        Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Welcome to your Welcome to your Welcome to your Welcome to yourWelcome to your  Parasite SEO dashboard!
      </Typography>
    </Box>
  );
}

export default Search;