import React, { useState } from 'react';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Card,
    CardContent,
    useTheme,
    useMediaQuery,
    Tabs,
    Tab,
} from "@mui/material";
import { PlayCircleOutline as VideoIcon, LocalFireDepartment as FireIcon } from '@mui/icons-material';


const videos = [
    {
        "title": "Full Guide",
        "type": "basics",
        "embedCode": `<iframe src="https://player.vimeo.com/video/1002768752?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="How To Use The Parasite SEO TOOL - ParasiteSEO"></iframe>`,
    },
    {
        "title": "Main Dashboard",
        "type": "basics",
        "embedCode": "<div style=\"padding:53.13% 0 0 0;position:relative;\"><iframe src=\"https://player.vimeo.com/video/1002394376?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture; clipboard-write\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" title=\"ParasiteSEO.com Dashboard Overview\"></iframe></div><script src=\"https://player.vimeo.com/api/player.js\"></script>"
    },
    {
        "title": "Using Projects",
        "type": "basics",
        "embedCode": "<div style=\"padding:53.13% 0 0 0;position:relative;\"><iframe src=\"https://player.vimeo.com/video/1002397462?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture; clipboard-write\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" title=\"Projects in Parasite SEO\"></iframe></div><script src=\"https://player.vimeo.com/api/player.js\"></script>"
    },
    {
        "title": "AI Overview",
        "type": "basics",
        "embedCode": "<div style=\"padding:53.13% 0 0 0;position:relative;\"><iframe src=\"https://player.vimeo.com/video/1002403090?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture; clipboard-write\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" title=\"AI Overview Parasite SEO\"></iframe></div><script src=\"https://player.vimeo.com/api/player.js\"></script>"
    },
    {
        "title": "Bulk Analyses",
        "type": "basics",
        "embedCode": "<div style=\"padding:53.13% 0 0 0;position:relative;\"><iframe src=\"https://player.vimeo.com/video/1002412529?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture; clipboard-write\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" title=\"Bulk Analysis Parasite SEO\"></iframe></div><script src=\"https://player.vimeo.com/api/player.js\"></script>"
    },
    {
        "title": "Fundamentals Module 1",
        "type": "course",
        "embedCode": "<div style=\"padding:57.94% 0 0 0;position:relative;\"><iframe src=\"https://player.vimeo.com/video/1002378475?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479\" frameborder=\"0\" allow=\"autoplay; fullscreen; picture-in-picture; clipboard-write\" style=\"position:absolute;top:0;left:0;width:100%;height:100%;\" title=\"Parasite Fundamentals\"></iframe></div><script src=\"https://player.vimeo.com/api/player.js\"></script>"
    }
];

function VideoScreen() {
    const [filter, setFilter] = useState('course');
    const [selectedVideo, setSelectedVideo] = useState(videos.find(v => v.type === 'course') || videos[0]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const filteredVideos = videos.filter(video => video.type === filter);

    const handleTabChange = (event, newValue) => {
        setFilter(newValue);
        setSelectedVideo(videos.find(v => v.type === newValue) || videos[0]);
    };

    return (
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            height: "calc(100vh - 164px)",
            bgcolor: "#121212",
          }}
        >
          {/* Left Panel - Video List */}
          <Paper
            elevation={3}
            sx={{
              width: isMobile ? "100%" : 300,
              display: "flex",
              flexDirection: "column",
              height: isMobile ? "auto" : "100%",
              overflow: "hidden",
              bgcolor: "#0000005e",
            }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ color: "#fff", mb: 2 }}>
                Videos
              </Typography>
              <Tabs
                value={filter}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{
                  '& .MuiTab-root': {
                    color: '#fff',
                    '&.Mui-selected': {
                      color: '#9333EA',
                    },
                  },
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#9333EA',
                  },
                }}
              >
                <Tab 
                  label="App Basics" 
                  value="basics" 
                />
                <Tab
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      Fundamentals Course
                      <FireIcon sx={{ ml: 1, fontSize: 18, color: '#FF6B6B' }} />
                    </Box>
                  }
                  value="course"
                />
              </Tabs>
            </Box>
            <List
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                maxHeight: isMobile ? "300px" : "calc(100vh - 300px)",
                "&::-webkit-scrollbar": {
                  width: "12px",
                  backgroundColor: "#181818",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "6px",
                  backgroundColor: "#9333EA",
                },
                "& .MuiListItem-root:hover": {
                  backgroundColor: "#222223",
                },
              }}
            >
              {filteredVideos.map((video) => (
                <ListItem
                  key={video.title}
                  button
                  onClick={() => setSelectedVideo(video)}
                  selected={selectedVideo.title === video.title}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#333",
                    },
                  }}
                >
                  <ListItemIcon>
                    <VideoIcon sx={{ color: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={video.title}
                    primaryTypographyProps={{ sx: { color: "#fff" } }}
                  />
                </ListItem>
              ))}
              {filter === 'course' && (
                <ListItem>
                  <ListItemText
                    primary="More coming soon!"
                    primaryTypographyProps={{ sx: { color: "#fff", fontStyle: "italic" } }}
                  />
                </ListItem>
              )}
            </List>
          </Paper>
      
          {/* Right Panel - Video Player */}
          <Box
            sx={{
              flexGrow: 1,
              pl: { xs: 0, sm: 2 },
              display: "flex",
              flexDirection: "column",
              height: isMobile ? "auto" : "calc(100vh - 164px)",
              overflow: "hidden",
              mt: { xs: 2, sm: 0 },
              width: isMobile ? '100%' : 'calc(100% - 300px)',
            }}
          >
            <Card
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                bgcolor: "#1E1E1E",
              }}
            >
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <Typography variant="h6" sx={{ color: "#fff", mb: 2 }}>
                  {selectedVideo.title}
                </Typography>
                <Box
                  sx={{
                    flexGrow: 1,
                    '& iframe': {
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: selectedVideo.embedCode }}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      );
}

export default VideoScreen;