// src/components/AddProjectModal.js
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box,
    IconButton,
    CircularProgress
} from '@mui/material';
import {
    Close as CloseIcon,
} from '@mui/icons-material';
import CountrySelector from '../Dashboard/CountrySelector';

function AddProjectModal({ open, onClose, onSubmit, project, initialCountry, disableCountrySelection }) {
    const [projectName, setProjectName] = useState('');
    const [country, setCountry] = useState(initialCountry || 'us');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (project) {
            setProjectName(project.name);
            setCountry(project.country || initialCountry || 'us');
        } else {
            resetForm();
        }
    }, [project, initialCountry]);

    const handleSubmit = async () => {
        if (!projectName) {
            return;
        }

        setIsLoading(true);

        const projectData = {
            name: projectName,
            country,
        };

        if (project) {
            projectData._id = project._id;
        }

        try {
            await onSubmit(projectData);
            resetForm();
        } catch (error) {
            // Error handling is done in the parent component
        } finally {
            setIsLoading(false);
        }
    };

    const resetForm = () => {
        setProjectName('');
        setCountry(initialCountry || 'us');
    };

    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: {
                    background: '#292929',
                    color: '#fff',
                },
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {project ? 'Rename Project' : 'Add New Project'}
                <IconButton onClick={handleClose} sx={{ color: '#fff' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Project Name"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        fullWidth
                        required
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#1E1E1E',
                                '& fieldset': { borderColor: '#444' },
                                '&:hover fieldset': { borderColor: '#666' },
                                '&.Mui-focused fieldset': { borderColor: '#fff' },
                            },
                            '& .MuiInputLabel-root': { color: '#999' },
                            '& input': { color: '#fff' },
                        }}
                    />
                    {!project && (
                        <CountrySelector
                            value={country}
                            onChange={setCountry}
                            disabled={disableCountrySelection}
                        />
                    )}
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                   
                </Box>
                <Box>
                    <Button onClick={handleClose} color="secondary" sx={{ color: '#fff', mr: 1, bgcolor:'#595959' }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={isLoading || !projectName}
                    >
                        {isLoading ? <CircularProgress size={24} /> : (project ? 'Update Project' : 'Create Project')}
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default AddProjectModal;